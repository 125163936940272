/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface InstrumentSearchDTO {
  assetSubType?: InstrumentSearchDTO.AssetSubTypeEnum;
  assetType?: InstrumentSearchDTO.AssetTypeEnum;
  bloombergId?: string;
  ccy?: string;
  exchangeName?: string;
  id?: string;
  isin?: string;
  minimumAmount?: number;
  minimumIncrement?: number;
  name?: string;
  nominalBondAmount?: number;
  nominalCertificate?: number;
  price?: number;
  sector?: string;
}
export namespace InstrumentSearchDTO {
  export type AssetSubTypeEnum =
    | 'AIRPLANE'
    | 'ALTERNATIVE_ASSETS'
    | 'ART_AND_COLLECTIBLES'
    | 'BASKET'
    | 'BOND'
    | 'BUILDINGS'
    | 'CAR'
    | 'CASH'
    | 'COMMODITIES'
    | 'CURRENCY'
    | 'DEBT'
    | 'DEPOSIT'
    | 'DIRECT'
    | 'EQUITIES'
    | 'FIXED_INCOME'
    | 'FUNDS'
    | 'FUTURES'
    | 'FX'
    | 'GRANTED_LOAN'
    | 'HARD_ASSETS'
    | 'HEDGEFUND'
    | 'HELICOPTER'
    | 'INDEX'
    | 'INTEREST_RATE'
    | 'LAND'
    | 'LOAN'
    | 'NOTE'
    | 'OPTION'
    | 'OTHER'
    | 'PRIVATE_EQUITY'
    | 'REAL_ESTATE'
    | 'REAL_ESTATE_INFRASTRUCTURE'
    | 'REVERSE'
    | 'STOCK_DIVIDEND'
    | 'SWAP'
    | 'TREASURY_BILL'
    | 'WARRANT';
  export const AssetSubTypeEnum = {
    Airplane: 'AIRPLANE' as AssetSubTypeEnum,
    AlternativeAssets: 'ALTERNATIVE_ASSETS' as AssetSubTypeEnum,
    ArtAndCollectibles: 'ART_AND_COLLECTIBLES' as AssetSubTypeEnum,
    Basket: 'BASKET' as AssetSubTypeEnum,
    Bond: 'BOND' as AssetSubTypeEnum,
    Buildings: 'BUILDINGS' as AssetSubTypeEnum,
    Car: 'CAR' as AssetSubTypeEnum,
    Cash: 'CASH' as AssetSubTypeEnum,
    Commodities: 'COMMODITIES' as AssetSubTypeEnum,
    Currency: 'CURRENCY' as AssetSubTypeEnum,
    Debt: 'DEBT' as AssetSubTypeEnum,
    Deposit: 'DEPOSIT' as AssetSubTypeEnum,
    Direct: 'DIRECT' as AssetSubTypeEnum,
    Equities: 'EQUITIES' as AssetSubTypeEnum,
    FixedIncome: 'FIXED_INCOME' as AssetSubTypeEnum,
    Funds: 'FUNDS' as AssetSubTypeEnum,
    Futures: 'FUTURES' as AssetSubTypeEnum,
    Fx: 'FX' as AssetSubTypeEnum,
    GrantedLoan: 'GRANTED_LOAN' as AssetSubTypeEnum,
    HardAssets: 'HARD_ASSETS' as AssetSubTypeEnum,
    Hedgefund: 'HEDGEFUND' as AssetSubTypeEnum,
    Helicopter: 'HELICOPTER' as AssetSubTypeEnum,
    Index: 'INDEX' as AssetSubTypeEnum,
    InterestRate: 'INTEREST_RATE' as AssetSubTypeEnum,
    Land: 'LAND' as AssetSubTypeEnum,
    Loan: 'LOAN' as AssetSubTypeEnum,
    Note: 'NOTE' as AssetSubTypeEnum,
    Option: 'OPTION' as AssetSubTypeEnum,
    Other: 'OTHER' as AssetSubTypeEnum,
    PrivateEquity: 'PRIVATE_EQUITY' as AssetSubTypeEnum,
    RealEstate: 'REAL_ESTATE' as AssetSubTypeEnum,
    RealEstateInfrastructure: 'REAL_ESTATE_INFRASTRUCTURE' as AssetSubTypeEnum,
    Reverse: 'REVERSE' as AssetSubTypeEnum,
    StockDividend: 'STOCK_DIVIDEND' as AssetSubTypeEnum,
    Swap: 'SWAP' as AssetSubTypeEnum,
    TreasuryBill: 'TREASURY_BILL' as AssetSubTypeEnum,
    Warrant: 'WARRANT' as AssetSubTypeEnum,
  };
  export type AssetTypeEnum =
    | 'ALTERNATIVE_ASSETS'
    | 'CASH'
    | 'CERTIFICATE'
    | 'COMMODITIES'
    | 'CRYPTO'
    | 'DEPOSIT'
    | 'DERIVATIVE'
    | 'EQUITIES'
    | 'ETF'
    | 'FIXED_INCOME'
    | 'FUNDS'
    | 'FUTURES'
    | 'FX'
    | 'GRANTED_LOAN'
    | 'HEDGE_FUND'
    | 'INDEX'
    | 'INTANGIBLE_ASSET'
    | 'LOAN'
    | 'MUTUAL_FUND'
    | 'OPTIONS'
    | 'OTHER'
    | 'PRIVATE_EQUITY'
    | 'RECOMMENDATION'
    | 'REPO'
    | 'SP'
    | 'STRATEGY'
    | 'TANGIBLE_ASSET';
  export const AssetTypeEnum = {
    AlternativeAssets: 'ALTERNATIVE_ASSETS' as AssetTypeEnum,
    Cash: 'CASH' as AssetTypeEnum,
    Certificate: 'CERTIFICATE' as AssetTypeEnum,
    Commodities: 'COMMODITIES' as AssetTypeEnum,
    Crypto: 'CRYPTO' as AssetTypeEnum,
    Deposit: 'DEPOSIT' as AssetTypeEnum,
    Derivative: 'DERIVATIVE' as AssetTypeEnum,
    Equities: 'EQUITIES' as AssetTypeEnum,
    Etf: 'ETF' as AssetTypeEnum,
    FixedIncome: 'FIXED_INCOME' as AssetTypeEnum,
    Funds: 'FUNDS' as AssetTypeEnum,
    Futures: 'FUTURES' as AssetTypeEnum,
    Fx: 'FX' as AssetTypeEnum,
    GrantedLoan: 'GRANTED_LOAN' as AssetTypeEnum,
    HedgeFund: 'HEDGE_FUND' as AssetTypeEnum,
    Index: 'INDEX' as AssetTypeEnum,
    IntangibleAsset: 'INTANGIBLE_ASSET' as AssetTypeEnum,
    Loan: 'LOAN' as AssetTypeEnum,
    MutualFund: 'MUTUAL_FUND' as AssetTypeEnum,
    Options: 'OPTIONS' as AssetTypeEnum,
    Other: 'OTHER' as AssetTypeEnum,
    PrivateEquity: 'PRIVATE_EQUITY' as AssetTypeEnum,
    Recommendation: 'RECOMMENDATION' as AssetTypeEnum,
    Repo: 'REPO' as AssetTypeEnum,
    Sp: 'SP' as AssetTypeEnum,
    Strategy: 'STRATEGY' as AssetTypeEnum,
    TangibleAsset: 'TANGIBLE_ASSET' as AssetTypeEnum,
  };
}
