import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, CanActivateChildFn } from '@angular/router';
import { AuthService } from '@shared/helpers/auth.service';
import { tap } from 'rxjs/operators';

export const AuthCanActivateGuard: CanActivateFn = () => {
  const auth = inject(AuthService);

  return auth.validate().pipe(
    tap((valid) => {
      if (!valid) {
        auth.logout();
      }
    }),
  );
};

export const AuthCanActivateChildGuard: CanActivateChildFn = () => {
  const auth = inject(AuthService);

  return auth.validate().pipe(
    tap((valid) => {
      if (!valid) {
        auth.logout();
      }
    }),
  );
};

export const AuthCanMatchGuard: CanMatchFn = () => {
  const auth = inject(AuthService);

  return auth.validate().pipe(
    tap((valid) => {
      if (!valid) {
        auth.logout();
      }
    }),
  );
};
