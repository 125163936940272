import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RoleState } from '@shared/states/role.state';
import { takeUntil } from 'rxjs/operators';
import { BrowserInfoService } from '@shared/helpers/browser-info.service';
import { IssuesService } from '@api/issues.service';
import { BehaviorSubject } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { DialogData, DIALOG_DATA } from '@ui/dialog/dialog.types';
import { JiraCreateIssueDTO } from '@shared/dto/gateway-secured/jira-create-issue-dto';
import { FeedbackFormGroup } from './feedback.types';
import { AttachmentTinyDTO } from '@shared/dto/gateway-secured/attachment-tiny-dto';
import { FileData } from '@ui/uploader/uploader.types';
import { FormHelper } from '@shared/helpers/form.helper';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent extends BaseObject {
  public readonly IssueType = JiraCreateIssueDTO.IssueTypeEnum;

  public readonly priorities = Object.values(JiraCreateIssueDTO.PriorityEnum);

  public formGroup: FormGroup<FeedbackFormGroup>;
  public loading$ = new BehaviorSubject<boolean>(false);
  public files$ = new BehaviorSubject<FileData<File>[]>([]);

  constructor(
    @Inject(DIALOG_DATA) public dialogData: DialogData<void, void>,
    private browserInfoService: BrowserInfoService,
    private issuesService: IssuesService,
    private roleState: RoleState,
  ) {
    super();

    this.createForm();
  }

  private createForm(): void {
    this.formGroup = new FormGroup<FeedbackFormGroup>({
      issueType: new FormControl<JiraCreateIssueDTO.IssueTypeEnum>('BUG', Validators.required),
      summary: new FormControl<string>(null, Validators.required),
      description: new FormControl<string>(null),
      priority: new FormControl<JiraCreateIssueDTO.PriorityEnum>('MEDIUM'),
    });
  }

  public _onSubmitClick(): void {
    if (this.formGroup.invalid) {
      FormHelper.markErrors(this.formGroup);
      return;
    }

    const formData = this.formGroup.value;

    const submitData: JiraCreateIssueDTO = {
      ...formData,
      attachments: this.files$.value.map((file) => {
        const attachment: AttachmentTinyDTO = {
          filename: file.name,
          base64Content: file.file as any as string,
        };
        return attachment;
      }),
      initiatorEmail: this.roleState.userIsClient
        ? this.roleState.client$.value.email
        : this.roleState.manager$.value.email,
      page: location.pathname,
      metaData: JSON.stringify({ ...this.browserInfoService.browserInfo }),
    };

    this.loading$.next(true);

    this.issuesService
      .createBug(submitData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.loading$.next(false);

        if (result) {
          this.dialogData.close();
        }
      });
  }
}
