import { Injectable } from '@angular/core';
import { Observable, map, of, lastValueFrom } from 'rxjs';
import { PortfolioGlobalState } from '@shared/states/portfolio-global.state';

@Injectable({
  providedIn: 'root',
})
export class InitAppService {
  constructor(private portfolioGlobalState: PortfolioGlobalState) {}

  public init(): Promise<void> {
    return lastValueFrom(this.initIds().pipe(map(() => null)));
  }

  private initIds(): Observable<void> {
    const portfolioId = this.portfolioGlobalState.getPortfolioIdFromUrl();
    const accountId = this.portfolioGlobalState.getAccountIdFromUrl();

    switch (true) {
      case !!portfolioId:
        this.portfolioGlobalState.setPortfolio(portfolioId);
        break;

      case !!accountId:
        this.portfolioGlobalState.setAccount(accountId);
        break;
    }

    return of(null);
  }
}
