import { Directive, Output, EventEmitter, HostBinding, HostListener, Input } from '@angular/core';
import { combineLatest } from 'rxjs';
import { UploaderService } from '@shared/helpers/uploader.service';
import { BaseObject } from '@shared/base/base-object';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appDnd]',
})
export class DndDirective extends BaseObject {
  @HostBinding('class.app-dnd') public readonly _hostClass = true;
  @HostBinding('class.app-dnd--file-over') public fileOver: boolean;

  @Input() public toFileData: boolean = false;
  @Input() public format: 'base64' | 'file' = 'file';
  @Input() public multiple: boolean = true;

  @Input()
  @HostBinding('class.app-dnd--disabled')
  public disabled: boolean = false;

  @Output() public readonly fileDropped = new EventEmitter<any>();

  constructor(private uploaderService: UploaderService) {
    super();
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  public ondrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer.files;
    this.emit(files);
  }

  private emit(files: { [n: number]: File; length?: number }): void {
    if (!files.length) {
      return;
    }

    const allFiles = this.multiple ? (Object.values(files) as File[]) : [files[0]];

    if (this.toFileData) {
      switch (this.format) {
        case 'base64':
          const observableFiles = allFiles.map((file) => this.uploaderService.fileToBase64(file));

          combineLatest(observableFiles)
            .pipe(takeUntil(this.destroy$))
            .subscribe((filesToUpload) => {
              this.fileDropped.emit(filesToUpload);
            });

          break;

        default:
          this.fileDropped.emit(allFiles.map((file) => ({ name: file.name, file })));
          break;
      }
    } else {
      this.fileDropped.emit(files);
    }
  }
}
