import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { DialogHelper } from './dialog.helper';

export const LOGIN_PAGE = '/login';
export const START_PAGE = '/dashboard';

@Injectable({ providedIn: 'root' })
export class RerdirectService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
  ) {}

  public get startPage(): string {
    return localStorage.getItem(LocalStorageConstants.StartPage) || START_PAGE;
  }

  public set startPage(value: string) {
    if (!value || value.endsWith(LOGIN_PAGE)) {
      localStorage.setItem(LocalStorageConstants.StartPage, START_PAGE);
    } else {
      localStorage.setItem(LocalStorageConstants.StartPage, value);
    }
  }

  public goToStartPage(): void {
    const startPage = this.startPage;

    location.replace(startPage);

    this.startPage = START_PAGE;
  }

  public goToLoginPage(withReload: boolean = false): void {
    this.startPage = location.href;

    DialogHelper.CloseAllDialogs();

    if (withReload) {
      location.replace(LOGIN_PAGE);
      location.reload();
    } else {
      this.router.navigate([LOGIN_PAGE]);
    }
  }

  public goToTermsOfUse(): void {
    const url = new URL(`${this.document.baseURI}terms-of-use`);
    window.open(url.href);
  }

  public goToCookiePolicy(): void {
    const url = new URL(`${this.document.baseURI}cookie-policy`);
    window.open(url.href);
  }

  public goToPrivacyPolicy(): void {
    const url = new URL(`${this.document.baseURI}privacy`);
    window.open(url.href);
  }
}
