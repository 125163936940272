export enum CurrencyCodes {
  EUR = 'EUR',
  USD = 'USD',
  RUB = 'RUB',
}

export enum SymbolPositions {
  Left = 'Left',
  Right = 'Right',
}

export const DECIMAL_SEPARATOR = '.';
export const THOUSAND_SEPARATOR = ' ';
export const DECIMALS_COUNT = 2;

export enum SystemDateFormats {
  yyyyMMdd = 'yyyy-MM-dd',
  ddMMyyyy = 'dd-MM-yyyy',
  MMddyyyy = 'MM-dd-yyyy',
}

export enum PageFormats {
  A4 = 'A4',
  A3 = 'A3',
  A2 = 'A2',
}

export const DateFormatSeparator = '.';

export interface PreferencesDTO {
  currency: CurrencyCodes | string;
  symbolPosition: SymbolPositions;
  decimalsCount: number;
  nativeCurrencyHover: boolean;
  dateFormat: SystemDateFormats;
  pageFormat: PageFormats;
  dailyProfileUpdates: boolean;
  language: string;
  getMetaDataFromServer?: boolean;
  automaticExecution?: boolean;
  skipDuplicateOrderWindow?: boolean;
  cookieAccepted: boolean;
}
