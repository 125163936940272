/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PrivateTransactionLinkDTO {
  bankAccountId: string;
  commission?: number;
  price?: number;
  qty?: number;
  subType?: PrivateTransactionLinkDTO.SubTypeEnum;
  transactionId: string;
  type: PrivateTransactionLinkDTO.TypeEnum;
}
export namespace PrivateTransactionLinkDTO {
  export type SubTypeEnum =
    | 'ADMINISTRATION'
    | 'ASSIMILATION'
    | 'AUCTION_TENDER_OFFER'
    | 'BLOCKING'
    | 'BLOCKING_OF_CERTIFICATE'
    | 'BUY'
    | 'BUY_ISSUE_IPO'
    | 'CALLED'
    | 'CAPITAL_CALL'
    | 'CAPITAL_INCREASE'
    | 'COMMISSION'
    | 'COMPENSATION'
    | 'CONSENT'
    | 'CORP_ACTION'
    | 'COUPON'
    | 'CREDIT'
    | 'CUSTODY'
    | 'DEBIT'
    | 'DEPOSIT'
    | 'DIVIDEND'
    | 'DVP'
    | 'EXCHANGE_OF_SECURITES'
    | 'EXECUTION'
    | 'EXPIRY_OF_SECURITY'
    | 'FRACTIONAL_SHARE'
    | 'FROM_SPIN_OFF'
    | 'FULL'
    | 'HANDLE_FEE'
    | 'IN'
    | 'INFO'
    | 'INTEREST_CALCULATION'
    | 'INTERNAL'
    | 'INVOICE'
    | 'ISIN_CHANGE'
    | 'LIQUIDATION'
    | 'LOAN'
    | 'MANAGEMENT'
    | 'MERGE'
    | 'OPERATION'
    | 'OPTIONAL_DIVIDEND'
    | 'ORDER'
    | 'OTHER'
    | 'OUT'
    | 'PARTIAL'
    | 'PAYMENT'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIBE'
    | 'PUBLIC_OFFER'
    | 'PURCHASE_OFFER'
    | 'REPO'
    | 'RETRO'
    | 'REVENUE'
    | 'REVERSE_SPLIT'
    | 'SECURITIES_LANDING'
    | 'SECURITY_EXCHANGE'
    | 'SELL'
    | 'SERVICE_CHARGE_CALCULATION'
    | 'SPIN_OFF'
    | 'SPLIT'
    | 'SPLIT_ISIN_CHANGE'
    | 'SUBSCRIPTION'
    | 'SUCCESS'
    | 'TECHNICAL_CORRECTION'
    | 'TRADING'
    | 'UNBLOCKING'
    | 'UNBLOCKING_BLOCKING'
    | 'VARIATION_MARGIN';
  export const SubTypeEnum = {
    Administration: 'ADMINISTRATION' as SubTypeEnum,
    Assimilation: 'ASSIMILATION' as SubTypeEnum,
    AuctionTenderOffer: 'AUCTION_TENDER_OFFER' as SubTypeEnum,
    Blocking: 'BLOCKING' as SubTypeEnum,
    BlockingOfCertificate: 'BLOCKING_OF_CERTIFICATE' as SubTypeEnum,
    Buy: 'BUY' as SubTypeEnum,
    BuyIssueIpo: 'BUY_ISSUE_IPO' as SubTypeEnum,
    Called: 'CALLED' as SubTypeEnum,
    CapitalCall: 'CAPITAL_CALL' as SubTypeEnum,
    CapitalIncrease: 'CAPITAL_INCREASE' as SubTypeEnum,
    Commission: 'COMMISSION' as SubTypeEnum,
    Compensation: 'COMPENSATION' as SubTypeEnum,
    Consent: 'CONSENT' as SubTypeEnum,
    CorpAction: 'CORP_ACTION' as SubTypeEnum,
    Coupon: 'COUPON' as SubTypeEnum,
    Credit: 'CREDIT' as SubTypeEnum,
    Custody: 'CUSTODY' as SubTypeEnum,
    Debit: 'DEBIT' as SubTypeEnum,
    Deposit: 'DEPOSIT' as SubTypeEnum,
    Dividend: 'DIVIDEND' as SubTypeEnum,
    Dvp: 'DVP' as SubTypeEnum,
    ExchangeOfSecurites: 'EXCHANGE_OF_SECURITES' as SubTypeEnum,
    Execution: 'EXECUTION' as SubTypeEnum,
    ExpiryOfSecurity: 'EXPIRY_OF_SECURITY' as SubTypeEnum,
    FractionalShare: 'FRACTIONAL_SHARE' as SubTypeEnum,
    FromSpinOff: 'FROM_SPIN_OFF' as SubTypeEnum,
    Full: 'FULL' as SubTypeEnum,
    HandleFee: 'HANDLE_FEE' as SubTypeEnum,
    In: 'IN' as SubTypeEnum,
    Info: 'INFO' as SubTypeEnum,
    InterestCalculation: 'INTEREST_CALCULATION' as SubTypeEnum,
    Internal: 'INTERNAL' as SubTypeEnum,
    Invoice: 'INVOICE' as SubTypeEnum,
    IsinChange: 'ISIN_CHANGE' as SubTypeEnum,
    Liquidation: 'LIQUIDATION' as SubTypeEnum,
    Loan: 'LOAN' as SubTypeEnum,
    Management: 'MANAGEMENT' as SubTypeEnum,
    Merge: 'MERGE' as SubTypeEnum,
    Operation: 'OPERATION' as SubTypeEnum,
    OptionalDividend: 'OPTIONAL_DIVIDEND' as SubTypeEnum,
    Order: 'ORDER' as SubTypeEnum,
    Other: 'OTHER' as SubTypeEnum,
    Out: 'OUT' as SubTypeEnum,
    Partial: 'PARTIAL' as SubTypeEnum,
    Payment: 'PAYMENT' as SubTypeEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as SubTypeEnum,
    ProductUnsubscribe: 'PRODUCT_UNSUBSCRIBE' as SubTypeEnum,
    PublicOffer: 'PUBLIC_OFFER' as SubTypeEnum,
    PurchaseOffer: 'PURCHASE_OFFER' as SubTypeEnum,
    Repo: 'REPO' as SubTypeEnum,
    Retro: 'RETRO' as SubTypeEnum,
    Revenue: 'REVENUE' as SubTypeEnum,
    ReverseSplit: 'REVERSE_SPLIT' as SubTypeEnum,
    SecuritiesLanding: 'SECURITIES_LANDING' as SubTypeEnum,
    SecurityExchange: 'SECURITY_EXCHANGE' as SubTypeEnum,
    Sell: 'SELL' as SubTypeEnum,
    ServiceChargeCalculation: 'SERVICE_CHARGE_CALCULATION' as SubTypeEnum,
    SpinOff: 'SPIN_OFF' as SubTypeEnum,
    Split: 'SPLIT' as SubTypeEnum,
    SplitIsinChange: 'SPLIT_ISIN_CHANGE' as SubTypeEnum,
    Subscription: 'SUBSCRIPTION' as SubTypeEnum,
    Success: 'SUCCESS' as SubTypeEnum,
    TechnicalCorrection: 'TECHNICAL_CORRECTION' as SubTypeEnum,
    Trading: 'TRADING' as SubTypeEnum,
    Unblocking: 'UNBLOCKING' as SubTypeEnum,
    UnblockingBlocking: 'UNBLOCKING_BLOCKING' as SubTypeEnum,
    VariationMargin: 'VARIATION_MARGIN' as SubTypeEnum,
  };
  export type TypeEnum =
    | 'ADJUSTMENT'
    | 'BLOCK'
    | 'BOND_REDEMPTION'
    | 'BOND_REDEMPTION_PARTIAL'
    | 'BUY'
    | 'CASH_TRANSFER'
    | 'CORPORATE_ACTION'
    | 'EXERCISE_OPTION'
    | 'EXPENSE'
    | 'EXPIRE_OPTION'
    | 'FEE'
    | 'FX'
    | 'INCOME'
    | 'INFO'
    | 'INTEREST'
    | 'INTERNAL_TRANSFER'
    | 'INVESTMENT'
    | 'MARGIN'
    | 'PAYMENT'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIPTION'
    | 'REPO'
    | 'REVENUE'
    | 'REVERSAL'
    | 'SECURITY_TRANSFER'
    | 'SELL'
    | 'TRANSFER'
    | 'UNKNOWN'
    | 'VIRTUAL_SECURITY_TRANSFER'
    | 'WITHDRAWAL';
  export const TypeEnum = {
    Adjustment: 'ADJUSTMENT' as TypeEnum,
    Block: 'BLOCK' as TypeEnum,
    BondRedemption: 'BOND_REDEMPTION' as TypeEnum,
    BondRedemptionPartial: 'BOND_REDEMPTION_PARTIAL' as TypeEnum,
    Buy: 'BUY' as TypeEnum,
    CashTransfer: 'CASH_TRANSFER' as TypeEnum,
    CorporateAction: 'CORPORATE_ACTION' as TypeEnum,
    ExerciseOption: 'EXERCISE_OPTION' as TypeEnum,
    Expense: 'EXPENSE' as TypeEnum,
    ExpireOption: 'EXPIRE_OPTION' as TypeEnum,
    Fee: 'FEE' as TypeEnum,
    Fx: 'FX' as TypeEnum,
    Income: 'INCOME' as TypeEnum,
    Info: 'INFO' as TypeEnum,
    Interest: 'INTEREST' as TypeEnum,
    InternalTransfer: 'INTERNAL_TRANSFER' as TypeEnum,
    Investment: 'INVESTMENT' as TypeEnum,
    Margin: 'MARGIN' as TypeEnum,
    Payment: 'PAYMENT' as TypeEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as TypeEnum,
    ProductUnsubscription: 'PRODUCT_UNSUBSCRIPTION' as TypeEnum,
    Repo: 'REPO' as TypeEnum,
    Revenue: 'REVENUE' as TypeEnum,
    Reversal: 'REVERSAL' as TypeEnum,
    SecurityTransfer: 'SECURITY_TRANSFER' as TypeEnum,
    Sell: 'SELL' as TypeEnum,
    Transfer: 'TRANSFER' as TypeEnum,
    Unknown: 'UNKNOWN' as TypeEnum,
    VirtualSecurityTransfer: 'VIRTUAL_SECURITY_TRANSFER' as TypeEnum,
    Withdrawal: 'WITHDRAWAL' as TypeEnum,
  };
}
