/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RelationDTO {
  entityType?: RelationDTO.EntityTypeEnum;
  fieldName?: string;
}
export namespace RelationDTO {
  export type EntityTypeEnum =
    | 'ACCOUNT'
    | 'ACCOUNT_COMPANY'
    | 'ACCOUNT_CONTACT'
    | 'ACL'
    | 'ADDRESS'
    | 'BANK_ACCOUNT'
    | 'CBH_CASH_POSITION'
    | 'CBH_CASH_TRANSACTION'
    | 'CBH_COUPONS_DIVIDENDS'
    | 'CBH_FIDUCIARY_DEPOSITS_LOANS'
    | 'CBH_FOREX'
    | 'CBH_SECURITY_POSITION'
    | 'CBH_SECURITY_TRANSACTION'
    | 'CHANGE_REQUEST'
    | 'CLIENT'
    | 'CLIENT_PORTFOLIO_IN_CR_TRANSACTION'
    | 'CLIENT_REQUEST_OPPORTUNITY'
    | 'COMPANY'
    | 'COMPANY_PORTFOLIO_IN_CR_TRANSACTION'
    | 'COMPANY_REPORT'
    | 'CONSOLIDATED_MANAGER_ACCOUNT'
    | 'COUNTERPARTY'
    | 'COUNTRY'
    | 'COUNTRY_OF_TAXATION_AND_TIN'
    | 'CUSTODIAN'
    | 'DBS_POSITION'
    | 'DBS_TRANSACTION'
    | 'EDR_CASH_TRANSACTION'
    | 'EDR_POSITION'
    | 'EDR_SECURITY_TRANSACTION'
    | 'EXECGW_REPORT'
    | 'FEE'
    | 'GLOBAL_ALLOCATION'
    | 'GOVERNANCE_FIELDS'
    | 'GPP_CASH_TRANSACTION'
    | 'GPP_POSITION'
    | 'GPP_TRADE_TRANSACTION'
    | 'HOLDING'
    | 'IBKR_CASH_POSITION'
    | 'IBKR_CASH_REPORT_TRANSACTION'
    | 'IBKR_COMMISSION_ADJUSTMENT_TRANSACTION'
    | 'IBKR_CORPORATE_ACTION_TRANSACTION'
    | 'IBKR_DEPOSIT_TRANSACTION'
    | 'IBKR_DIVIDEND_TRANSACTION'
    | 'IBKR_FEE_TRANSACTION'
    | 'IBKR_INTEREST_TRANSACTION'
    | 'IBKR_MTM_PERFORMANCE_SUMMARY_TRANSACTION'
    | 'IBKR_NEW_REPORT_TRANSACTION'
    | 'IBKR_OPEN_POSITION'
    | 'IBKR_TRADE_TRANSACTION'
    | 'IBKR_TRANSFER_TRANSACTION'
    | 'IBKR_UNIFIED_TRANSACTION'
    | 'IBKR_WITHHOLDING_TAX_TRANSACTION'
    | 'INSTRUMENT'
    | 'INSTRUMENT_IN_POSITION'
    | 'INVESTEC_TRANSACTION'
    | 'JB_POSITION'
    | 'JB_TRANSACTION'
    | 'JPM_POSITION'
    | 'JPM_TRANSACTION'
    | 'LEAD'
    | 'LEAD_OPPORTUNITY'
    | 'MANAGER'
    | 'MANDATE'
    | 'MANDATE_AUTHORIZED_PERSON'
    | 'MARKET_SECURITIES_CASH_POSITION'
    | 'MARKET_SECURITIES_CASH_TRANSACTION'
    | 'MARKET_SECURITIES_SECURITY_POSITION'
    | 'MARKET_SECURITIES_SECURITY_TRANSACTION'
    | 'NESTED_POSITION_DATA'
    | 'NOTE'
    | 'OPPORTUNITY_COMMENT'
    | 'OPPORTUNITY_MANAGER'
    | 'ORDER'
    | 'PORTFOLIO'
    | 'PORTFOLIO_BANK_ACCOUNT'
    | 'PORTFOLIO_DETAILS'
    | 'PORTFOLIO_MANAGER'
    | 'PORTFOLIO_TINY'
    | 'POSITION'
    | 'POSITION_BA'
    | 'PRIVATE_INSTRUMENT'
    | 'PRODUCT'
    | 'PRODUCT_VIEW'
    | 'PROFILE'
    | 'RELUNA_ORDERS_REPORT'
    | 'REPORT'
    | 'RESTRICTION'
    | 'RESTRICTION_ALERT'
    | 'RISK_PROFILE'
    | 'ROLE'
    | 'SOVA_POSITION'
    | 'SOVA_TRANSACTION'
    | 'STRATEGY'
    | 'TAVIRA_CASH_POSITION'
    | 'TAVIRA_POSITION'
    | 'TAVIRA_SECURITY_POSITION'
    | 'TAVIRA_TRADE2'
    | 'TAVIRA_TRANSACTION'
    | 'TAVIRA_TRANSACTION2'
    | 'TRADE_TYPE'
    | 'TRANSACTION'
    | 'TRANSACTION_CONCISE'
    | 'TRIMP_POSITION'
    | 'TRIMP_TRANSACTION'
    | 'UBS_EBICS_POSITION'
    | 'UBS_EBICS_SHORT_TRANSACTION'
    | 'UBS_EBICS_TRANSACTION'
    | 'USER'
    | 'VP_AAT_TRANSACTION'
    | 'VP_EXP_TRANSACTION'
    | 'VP_FTS_EXTERNAL_TRANSACTION'
    | 'VP_FTS_TRANSACTION'
    | 'VP_POS_TRANSACTION'
    | 'VP_SXT_TRANSACTION'
    | 'VP_ZAV_TRANSACTION';
  export const EntityTypeEnum = {
    Account: 'ACCOUNT' as EntityTypeEnum,
    AccountCompany: 'ACCOUNT_COMPANY' as EntityTypeEnum,
    AccountContact: 'ACCOUNT_CONTACT' as EntityTypeEnum,
    Acl: 'ACL' as EntityTypeEnum,
    Address: 'ADDRESS' as EntityTypeEnum,
    BankAccount: 'BANK_ACCOUNT' as EntityTypeEnum,
    CbhCashPosition: 'CBH_CASH_POSITION' as EntityTypeEnum,
    CbhCashTransaction: 'CBH_CASH_TRANSACTION' as EntityTypeEnum,
    CbhCouponsDividends: 'CBH_COUPONS_DIVIDENDS' as EntityTypeEnum,
    CbhFiduciaryDepositsLoans: 'CBH_FIDUCIARY_DEPOSITS_LOANS' as EntityTypeEnum,
    CbhForex: 'CBH_FOREX' as EntityTypeEnum,
    CbhSecurityPosition: 'CBH_SECURITY_POSITION' as EntityTypeEnum,
    CbhSecurityTransaction: 'CBH_SECURITY_TRANSACTION' as EntityTypeEnum,
    ChangeRequest: 'CHANGE_REQUEST' as EntityTypeEnum,
    Client: 'CLIENT' as EntityTypeEnum,
    ClientPortfolioInCrTransaction: 'CLIENT_PORTFOLIO_IN_CR_TRANSACTION' as EntityTypeEnum,
    ClientRequestOpportunity: 'CLIENT_REQUEST_OPPORTUNITY' as EntityTypeEnum,
    Company: 'COMPANY' as EntityTypeEnum,
    CompanyPortfolioInCrTransaction: 'COMPANY_PORTFOLIO_IN_CR_TRANSACTION' as EntityTypeEnum,
    CompanyReport: 'COMPANY_REPORT' as EntityTypeEnum,
    ConsolidatedManagerAccount: 'CONSOLIDATED_MANAGER_ACCOUNT' as EntityTypeEnum,
    Counterparty: 'COUNTERPARTY' as EntityTypeEnum,
    Country: 'COUNTRY' as EntityTypeEnum,
    CountryOfTaxationAndTin: 'COUNTRY_OF_TAXATION_AND_TIN' as EntityTypeEnum,
    Custodian: 'CUSTODIAN' as EntityTypeEnum,
    DbsPosition: 'DBS_POSITION' as EntityTypeEnum,
    DbsTransaction: 'DBS_TRANSACTION' as EntityTypeEnum,
    EdrCashTransaction: 'EDR_CASH_TRANSACTION' as EntityTypeEnum,
    EdrPosition: 'EDR_POSITION' as EntityTypeEnum,
    EdrSecurityTransaction: 'EDR_SECURITY_TRANSACTION' as EntityTypeEnum,
    ExecgwReport: 'EXECGW_REPORT' as EntityTypeEnum,
    Fee: 'FEE' as EntityTypeEnum,
    GlobalAllocation: 'GLOBAL_ALLOCATION' as EntityTypeEnum,
    GovernanceFields: 'GOVERNANCE_FIELDS' as EntityTypeEnum,
    GppCashTransaction: 'GPP_CASH_TRANSACTION' as EntityTypeEnum,
    GppPosition: 'GPP_POSITION' as EntityTypeEnum,
    GppTradeTransaction: 'GPP_TRADE_TRANSACTION' as EntityTypeEnum,
    Holding: 'HOLDING' as EntityTypeEnum,
    IbkrCashPosition: 'IBKR_CASH_POSITION' as EntityTypeEnum,
    IbkrCashReportTransaction: 'IBKR_CASH_REPORT_TRANSACTION' as EntityTypeEnum,
    IbkrCommissionAdjustmentTransaction: 'IBKR_COMMISSION_ADJUSTMENT_TRANSACTION' as EntityTypeEnum,
    IbkrCorporateActionTransaction: 'IBKR_CORPORATE_ACTION_TRANSACTION' as EntityTypeEnum,
    IbkrDepositTransaction: 'IBKR_DEPOSIT_TRANSACTION' as EntityTypeEnum,
    IbkrDividendTransaction: 'IBKR_DIVIDEND_TRANSACTION' as EntityTypeEnum,
    IbkrFeeTransaction: 'IBKR_FEE_TRANSACTION' as EntityTypeEnum,
    IbkrInterestTransaction: 'IBKR_INTEREST_TRANSACTION' as EntityTypeEnum,
    IbkrMtmPerformanceSummaryTransaction:
      'IBKR_MTM_PERFORMANCE_SUMMARY_TRANSACTION' as EntityTypeEnum,
    IbkrNewReportTransaction: 'IBKR_NEW_REPORT_TRANSACTION' as EntityTypeEnum,
    IbkrOpenPosition: 'IBKR_OPEN_POSITION' as EntityTypeEnum,
    IbkrTradeTransaction: 'IBKR_TRADE_TRANSACTION' as EntityTypeEnum,
    IbkrTransferTransaction: 'IBKR_TRANSFER_TRANSACTION' as EntityTypeEnum,
    IbkrUnifiedTransaction: 'IBKR_UNIFIED_TRANSACTION' as EntityTypeEnum,
    IbkrWithholdingTaxTransaction: 'IBKR_WITHHOLDING_TAX_TRANSACTION' as EntityTypeEnum,
    Instrument: 'INSTRUMENT' as EntityTypeEnum,
    InstrumentInPosition: 'INSTRUMENT_IN_POSITION' as EntityTypeEnum,
    InvestecTransaction: 'INVESTEC_TRANSACTION' as EntityTypeEnum,
    JbPosition: 'JB_POSITION' as EntityTypeEnum,
    JbTransaction: 'JB_TRANSACTION' as EntityTypeEnum,
    JpmPosition: 'JPM_POSITION' as EntityTypeEnum,
    JpmTransaction: 'JPM_TRANSACTION' as EntityTypeEnum,
    Lead: 'LEAD' as EntityTypeEnum,
    LeadOpportunity: 'LEAD_OPPORTUNITY' as EntityTypeEnum,
    Manager: 'MANAGER' as EntityTypeEnum,
    Mandate: 'MANDATE' as EntityTypeEnum,
    MandateAuthorizedPerson: 'MANDATE_AUTHORIZED_PERSON' as EntityTypeEnum,
    MarketSecuritiesCashPosition: 'MARKET_SECURITIES_CASH_POSITION' as EntityTypeEnum,
    MarketSecuritiesCashTransaction: 'MARKET_SECURITIES_CASH_TRANSACTION' as EntityTypeEnum,
    MarketSecuritiesSecurityPosition: 'MARKET_SECURITIES_SECURITY_POSITION' as EntityTypeEnum,
    MarketSecuritiesSecurityTransaction: 'MARKET_SECURITIES_SECURITY_TRANSACTION' as EntityTypeEnum,
    NestedPositionData: 'NESTED_POSITION_DATA' as EntityTypeEnum,
    Note: 'NOTE' as EntityTypeEnum,
    OpportunityComment: 'OPPORTUNITY_COMMENT' as EntityTypeEnum,
    OpportunityManager: 'OPPORTUNITY_MANAGER' as EntityTypeEnum,
    Order: 'ORDER' as EntityTypeEnum,
    Portfolio: 'PORTFOLIO' as EntityTypeEnum,
    PortfolioBankAccount: 'PORTFOLIO_BANK_ACCOUNT' as EntityTypeEnum,
    PortfolioDetails: 'PORTFOLIO_DETAILS' as EntityTypeEnum,
    PortfolioManager: 'PORTFOLIO_MANAGER' as EntityTypeEnum,
    PortfolioTiny: 'PORTFOLIO_TINY' as EntityTypeEnum,
    Position: 'POSITION' as EntityTypeEnum,
    PositionBa: 'POSITION_BA' as EntityTypeEnum,
    PrivateInstrument: 'PRIVATE_INSTRUMENT' as EntityTypeEnum,
    Product: 'PRODUCT' as EntityTypeEnum,
    ProductView: 'PRODUCT_VIEW' as EntityTypeEnum,
    Profile: 'PROFILE' as EntityTypeEnum,
    RelunaOrdersReport: 'RELUNA_ORDERS_REPORT' as EntityTypeEnum,
    Report: 'REPORT' as EntityTypeEnum,
    Restriction: 'RESTRICTION' as EntityTypeEnum,
    RestrictionAlert: 'RESTRICTION_ALERT' as EntityTypeEnum,
    RiskProfile: 'RISK_PROFILE' as EntityTypeEnum,
    Role: 'ROLE' as EntityTypeEnum,
    SovaPosition: 'SOVA_POSITION' as EntityTypeEnum,
    SovaTransaction: 'SOVA_TRANSACTION' as EntityTypeEnum,
    Strategy: 'STRATEGY' as EntityTypeEnum,
    TaviraCashPosition: 'TAVIRA_CASH_POSITION' as EntityTypeEnum,
    TaviraPosition: 'TAVIRA_POSITION' as EntityTypeEnum,
    TaviraSecurityPosition: 'TAVIRA_SECURITY_POSITION' as EntityTypeEnum,
    TaviraTrade2: 'TAVIRA_TRADE2' as EntityTypeEnum,
    TaviraTransaction: 'TAVIRA_TRANSACTION' as EntityTypeEnum,
    TaviraTransaction2: 'TAVIRA_TRANSACTION2' as EntityTypeEnum,
    TradeType: 'TRADE_TYPE' as EntityTypeEnum,
    Transaction: 'TRANSACTION' as EntityTypeEnum,
    TransactionConcise: 'TRANSACTION_CONCISE' as EntityTypeEnum,
    TrimpPosition: 'TRIMP_POSITION' as EntityTypeEnum,
    TrimpTransaction: 'TRIMP_TRANSACTION' as EntityTypeEnum,
    UbsEbicsPosition: 'UBS_EBICS_POSITION' as EntityTypeEnum,
    UbsEbicsShortTransaction: 'UBS_EBICS_SHORT_TRANSACTION' as EntityTypeEnum,
    UbsEbicsTransaction: 'UBS_EBICS_TRANSACTION' as EntityTypeEnum,
    User: 'USER' as EntityTypeEnum,
    VpAatTransaction: 'VP_AAT_TRANSACTION' as EntityTypeEnum,
    VpExpTransaction: 'VP_EXP_TRANSACTION' as EntityTypeEnum,
    VpFtsExternalTransaction: 'VP_FTS_EXTERNAL_TRANSACTION' as EntityTypeEnum,
    VpFtsTransaction: 'VP_FTS_TRANSACTION' as EntityTypeEnum,
    VpPosTransaction: 'VP_POS_TRANSACTION' as EntityTypeEnum,
    VpSxtTransaction: 'VP_SXT_TRANSACTION' as EntityTypeEnum,
    VpZavTransaction: 'VP_ZAV_TRANSACTION' as EntityTypeEnum,
  };
}
