import { Directive, Input, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectComponent } from './select.component';
import { SelectOption } from './select.types';

export interface SelectContext {
  selected$: BehaviorSubject<SelectOption>;
}

export interface SelectOptionDefContext<D> {
  $implicit: SelectOption<D>;
  index: number;
  context: SelectContext;
}

@Directive({
  selector: '[appSelectOptionDef]',
})
export class SelectOptionDefDirective<D> {
  // (Optional) for select options typing inside template.
  // Get current option type from given select component and set the type to SelectOptionDefContext
  @Input('appSelectOptionDefFor') public for: SelectComponent<D>;

  constructor(public template: TemplateRef<SelectOptionDefContext<D>>) {}

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard<D>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: SelectOptionDefDirective<D>,
    ctx: unknown,
  ): ctx is SelectOptionDefContext<D> {
    return true;
  }
}
