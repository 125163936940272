import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const SCOPES = ['common', 'dialogs', 'errors', 'marketplace', 'pages', 'shared', 'widgets'];

export class CustomTranslateLoader implements TranslateLoader {
  constructor(
    private prefix: string = 'i18n',
    private suffix: string = '.json',
    private http: HttpClient,
  ) {}

  public getTranslation(lang: string): Observable<unknown> {
    return forkJoin(
      SCOPES.map((scope) => this.http.get(`${this.prefix}${lang}/${scope}${this.suffix}`)),
    ).pipe(map((response) => response.reduce((list, item) => ({ ...list, ...item }), {})));
  }
}
