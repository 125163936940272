<div class="app-dialog-notification">
  <div class="app-dialog-notification__header">
    <app-svg class="app-dialog-notification__icon"
             [icon]="'AttentionSquare'"
             [disabledHover]="true"
             [subType]="data.subType">
    </app-svg>

    <span class="heading-4-special">
      Notice
    </span>

    <app-svg [icon]="'Close_24'"
             (click)="_onCloseClick()"
             class="app-dialog-notification__close">
    </app-svg>
  </div>

  <div class="app-dialog-notification__body">
    <span class="subtitle-2"
          style="padding-top: 5px;">
      {{ data.description }}
    </span>
  </div>

  <div class="app-dialog-notification__footer">
    <app-button type="secondary"
                (click)="_onCloseClick()"
                style="margin-right: 10px;">
      {{ 'common.close'|translate }}
    </app-button>
  </div>
</div>