import { Injectable } from '@angular/core';
import { TransactionDTO } from '@shared/dto/positions/transaction-dto';
import { InstrumentSearchDTO } from '@shared/dto/positions/instrument-search-dto';
import { GetNumberWithRound } from '@shared/pipes/number.pipe';

@Injectable({
  providedIn: 'root',
})
export class TransactionFieldsService {
  constructor() {}

  public getValue({
    type,
    qty,
    price,
    instrument,
  }: {
    type: TransactionDTO.TypeEnum;
    qty: number;
    price?: number;
    instrument?: InstrumentSearchDTO;
  }): number {
    let value: number;

    switch (type) {
      case TransactionDTO.TypeEnum.BondRedemption:
      case TransactionDTO.TypeEnum.BondRedemptionPartial: {
        value = (qty * price) / 100;
        break;
      }

      case TransactionDTO.TypeEnum.Sell:
      case TransactionDTO.TypeEnum.Buy:
        if (instrument.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome) {
          value = (qty * price) / 100;
        } else {
          value = qty * price;
        }
        break;

      case TransactionDTO.TypeEnum.Fee:
      case TransactionDTO.TypeEnum.Withdrawal:
      case TransactionDTO.TypeEnum.Fx:
      case TransactionDTO.TypeEnum.Investment:
        value = qty;
        break;

      case TransactionDTO.TypeEnum.Income:
        value =
          qty *
          price *
          (instrument.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome ? 1 / 100 : 1);
        break;

      case TransactionDTO.TypeEnum.SecurityTransfer:
        if (instrument.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome) {
          value = (qty * price) / 100;
        } else {
          value = qty * price;
        }
        break;

      default:
        value = 0;
        break;
    }

    return GetNumberWithRound(value, 2);
  }

  public getGrossAmount({
    type,
    qty,
    price,
    transactionSubType,
    instrument,
    accrued = 0,
  }: {
    type: TransactionDTO.TypeEnum;
    qty: number;
    price?: number;
    transactionSubType?: TransactionDTO.SubTypeEnum;
    instrument?: InstrumentSearchDTO;
    accrued?: number;
  }): number {
    let grossAmount: number;

    switch (type) {
      case TransactionDTO.TypeEnum.BondRedemption:
      case TransactionDTO.TypeEnum.BondRedemptionPartial: {
        grossAmount = (qty * price) / 100;
        break;
      }

      case TransactionDTO.TypeEnum.Sell:
        if (instrument.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome) {
          grossAmount = (qty * price) / 100 + accrued;
        } else {
          grossAmount = qty * price;
        }
        break;

      case TransactionDTO.TypeEnum.Buy:
        if (instrument.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome) {
          grossAmount = -(qty * price) / 100 - accrued;
        } else {
          grossAmount = -qty * price;
        }
        break;

      case TransactionDTO.TypeEnum.Fee:
      case TransactionDTO.TypeEnum.Withdrawal:
        grossAmount = -qty;
        break;

      case TransactionDTO.TypeEnum.Fx:
        grossAmount = transactionSubType === TransactionDTO.SubTypeEnum.Debit ? -qty : qty;
        break;

      case TransactionDTO.TypeEnum.Income:
        grossAmount =
          qty *
          price *
          (instrument.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome ? 1 / 100 : 1);
        break;

      case TransactionDTO.TypeEnum.Investment:
        grossAmount = qty;
        break;

      case TransactionDTO.TypeEnum.SecurityTransfer:
      default:
        grossAmount = 0;
    }

    return GetNumberWithRound(grossAmount, 2);
  }

  public getNetAmount({
    type,
    qty,
    commission = 0,
    price,
    transactionSubType,
    instrument,
    accrued = 0,
  }: {
    type: TransactionDTO.TypeEnum;
    qty: number;
    commission: number;
    price?: number;
    transactionSubType?: TransactionDTO.SubTypeEnum;
    instrument?: InstrumentSearchDTO;
    accrued?: number;
  }): number {
    let netAmount: number;

    switch (type) {
      case TransactionDTO.TypeEnum.BondRedemption:
      case TransactionDTO.TypeEnum.BondRedemptionPartial:
        netAmount = (qty * price) / 100 - commission;
        break;

      case TransactionDTO.TypeEnum.Sell:
        if (instrument?.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome) {
          netAmount = (qty * price) / 100 + accrued - commission;
        } else {
          netAmount = qty * price - commission;
        }
        break;

      case TransactionDTO.TypeEnum.Buy:
        if (instrument?.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome) {
          netAmount = -(qty * price) / 100 - accrued - commission;
        } else {
          netAmount = -qty * price - commission;
        }
        break;

      case TransactionDTO.TypeEnum.Fee:
      case TransactionDTO.TypeEnum.Withdrawal:
        netAmount = -qty - commission;
        break;

      case TransactionDTO.TypeEnum.Fx:
        netAmount =
          (transactionSubType === TransactionDTO.SubTypeEnum.Debit ? -qty : qty) - commission;
        break;

      case TransactionDTO.TypeEnum.Income:
        netAmount =
          qty *
            price *
            (instrument?.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome
              ? 1 / 100
              : 1) -
          commission;
        break;

      case TransactionDTO.TypeEnum.Investment:
        netAmount = qty - commission;
        break;

      case TransactionDTO.TypeEnum.SecurityTransfer:
        netAmount = -commission;
        break;

      default:
        netAmount = 0;
    }

    return GetNumberWithRound(netAmount, 2);
  }
}
