import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PropType } from '@shared/base/types';
import { FormHelper } from '@shared/helpers/form.helper';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
} from 'rxjs';

@Pipe({
  name: 'getChanges',
})
export class GetChangesPipe implements PipeTransform {
  public transform<TControl extends AbstractControl>(
    formControl: TControl,
    options?: {
      debounceTime?: number;
      distinctUntilChanged?: boolean;
      distinctUntilKeyChanged?: keyof PropType<TControl, 'value'>;
    },
  ): Observable<PropType<TControl, 'value'>> {
    if (options) {
      switch (true) {
        case options.debounceTime > 0:
          return FormHelper.getChanges(formControl).pipe(debounceTime(options.debounceTime));

        case options.debounceTime > 0 && options.distinctUntilChanged:
          return FormHelper.getChanges(formControl).pipe(
            debounceTime(options.debounceTime),
            distinctUntilChanged(),
          );

        case options.debounceTime > 0 && !!options.distinctUntilKeyChanged:
          return FormHelper.getChanges(formControl).pipe(
            debounceTime(options.debounceTime),
            filter((v) => !!v),
            distinctUntilKeyChanged(options.distinctUntilKeyChanged),
          );

        case options.distinctUntilChanged:
          return FormHelper.getChanges(formControl).pipe(distinctUntilChanged());

        case !!options.distinctUntilKeyChanged:
          return FormHelper.getChanges(formControl).pipe(
            filter((v) => !!v),
            distinctUntilKeyChanged(options.distinctUntilKeyChanged),
          );

        default:
          return FormHelper.getChanges(formControl);
      }
    } else {
      return FormHelper.getChanges(formControl);
    }
  }
}
