<div *ngIf="showLabel"
     class="app-file-list_label control-heading">
  {{label}}
</div>

<div class="app-file-list__file-list app-scrollbar-block"
     appDnd
     [toFileData]="true"
     [format]="format"
     [multiple]="multiple"
     [disabled]="disabled"
     (fileDropped)="onChangeData($event)"
     (click)="onDndClick($event)">

  <div class="app-file-list_background"
       [class.--hide-background]="hideBackground$|async">
    <ng-container *ngTemplateOutlet="backgroundTemplate || defaultBackgroundTemplate"></ng-container>

    <ng-template #defaultBackgroundTemplate>
      <app-svg src="/assets/img/skeletons/documents.svg"
               [type]="'skeleton'"
               class="default-background-icon"
               [disabled]="disabled">
      </app-svg>
    </ng-template>
  </div>

  <div *ngFor="let file of files$|async as files; let index = index; trackBy:trackByIndex;"
       class="app-file-list__file-list-item app-border-bottom-10 app-bg-main">
    <div *ngIf="editableIndex !== index"
         class="file-list-item__text">
      {{files[index].name || 'File ' + index }}
    </div>

    <div *ngIf="editableIndex === index"
         class="file-list-item__input">
      <mat-form-field appearance="outline"
                      floatLabel="always">
        <input matInput
               type="text"
               [formControl]="nameControl">
      </mat-form-field>
    </div>

    <div *ngIf="editableIndex !== index"
         class="file-list-item__buttons">
      <app-svg *ngIf="!disabled && canEditName"
               [icon]="'Edit_24'"
               (click)="onStartEdit($event, index)">
      </app-svg>

      <app-svg *ngIf="!disabled"
               [icon]="'Close_24'"
               (click)="onDeleteFile($event, index)">
      </app-svg>
    </div>

    <div *ngIf="editableIndex === index"
         class="file-list-item__buttons">
      <app-svg [icon]="'Done_24'"
               (click)="onSuccessEdit($event, index)">
      </app-svg>

      <app-svg [icon]="'Clear'"
               (click)="onDeclineEdit($event)">
      </app-svg>
    </div>
  </div>
</div>

<div class="file-uploader">
  <app-file-uploader *ngIf="!disabled"
                     [multiple]="multiple"
                     [accept]="accept"
                     [format]="format"
                     (changeData)="onChangeData($event)">
    <app-button *ngIf="showButton"
                type="secondary">
      {{buttonText}}
    </app-button>
  </app-file-uploader>
</div>