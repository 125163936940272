import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateRange, MatDateRangePicker } from '@angular/material/datepicker';
import { contentAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { BehaviorSubject } from 'rxjs';
import { filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { InputState } from '../input.state';
import { InputDateRange } from '../input.types';
import { DATE_RANGE_PERIOD_NAMES, DatepickerPage, DateRangePeriod } from './daterange-picker.types';

@Component({
  selector: 'app-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contentAnimation],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-daterange-picker app-bg-main',
  },
})
export class DaterangePickerComponent extends BaseObject implements OnInit, AfterViewInit {
  @Input() public datepicker: MatDateRangePicker<Date>;
  @Input() public maxDate: Date;
  @Input() public minDate: Date;
  @Input() public defaultPeriod: DateRangePeriod;

  @Output() public closeEvent = new EventEmitter<InputDateRange | void>();

  public readonly DatepickerPage = DatepickerPage;

  public toggleControl = new UntypedFormControl();
  public rangeGroup = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  public page: DatepickerPage = DatepickerPage.Calendar;
  public previousPage: DatepickerPage = DatepickerPage.Calendar;

  public set range(value: InputDateRange) {
    this.rangeGroup.patchValue({
      start: value.start,
      end: value.end,
    });
  }

  public get range(): InputDateRange {
    return this.rangeGroup.value;
  }

  public range$ = new BehaviorSubject<InputDateRange>(null);

  private selectedDatesPair: Date[] = [];

  public dateRangePeriods: { value: DateRangePeriod; name: string }[] = [
    { value: DateRangePeriod.Last7Days, name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.Last7Days] },
    { value: DateRangePeriod.Next7Days, name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.Next7Days] },
    { value: DateRangePeriod.ThisMonth, name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.ThisMonth] },
    {
      value: DateRangePeriod.ThreeMonths,
      name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.ThreeMonths],
    },
    { value: DateRangePeriod.YTD, name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.YTD] },
    { value: DateRangePeriod.Year, name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.Year] },
    {
      value: DateRangePeriod.ThreeYears,
      name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.ThreeYears],
    },
    {
      value: DateRangePeriod.ThisQuarter,
      name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.ThisQuarter],
    },
    {
      value: DateRangePeriod.PastQuarter,
      name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.PastQuarter],
    },
    { value: DateRangePeriod.AllTime, name: DATE_RANGE_PERIOD_NAMES[DateRangePeriod.AllTime] },
  ];

  constructor(
    private el: ElementRef<HTMLElement>,
    private dateHelper: DateHelper,
    public inputState: InputState,
  ) {
    super();

    this.inputState.calendarClasses$.next(['app-daterange-picker__calendar']);

    this.rangeGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.range$.next(data);
    });

    this.listenToToggleChange();
  }

  public ngOnInit(): void {
    this.listenToCalendarOpen();
    this.listenToCalendarClose();
    this.listenToCalendarChangeByCellClick();
  }

  public ngAfterViewInit(): void {
    this.inputState.datepickerElement = this.el.nativeElement;
  }

  public get canSetTime(): boolean {
    return this.inputState.inputType === 'datetime-range';
  }

  private resetPage(): void {
    this.page = DatepickerPage.Calendar;
  }

  private setPage(toggleValue: DateRangePeriod): void {
    this.selectedDatesPair = [];

    switch (toggleValue) {
      case DateRangePeriod.Last7Days:
      case DateRangePeriod.Next7Days:
      case DateRangePeriod.ThisMonth:
      case DateRangePeriod.ThreeMonths:
      case DateRangePeriod.YTD:
      case DateRangePeriod.Year:
      case DateRangePeriod.ThreeYears:
      case DateRangePeriod.ThisQuarter:
      case DateRangePeriod.PastQuarter:
      case DateRangePeriod.AllTime: {
        this.page = DatepickerPage.Calendar;
        break;
      }
    }
  }

  private setRangeAndUpdateView(range: { start: Date; end: Date }): void {
    this.inputState.calendar$
      .pipe(
        filter((calendar) => !!calendar),
        first(),
      )
      .subscribe((calendar) => {
        this.range = range;
        calendar.selected = new DateRange(range.start, range.end);
        calendar._goToDateInView(range.start, 'month');
      });
  }

  private listenToToggleChange(): void {
    this.toggleControl.valueChanges
      .pipe(
        filter((toggleValue) => !!toggleValue),
        takeUntil(this.destroy$),
      )
      .subscribe((toggleValue: DateRangePeriod) => {
        switch (toggleValue) {
          case DateRangePeriod.Last7Days:
          case DateRangePeriod.Next7Days:
          case DateRangePeriod.ThisMonth:
          case DateRangePeriod.ThreeMonths:
          case DateRangePeriod.YTD:
          case DateRangePeriod.Year:
          case DateRangePeriod.ThreeYears:
          case DateRangePeriod.ThisQuarter:
          case DateRangePeriod.PastQuarter: {
            this.setRangeAndUpdateView(
              this.dateHelper.convertDateRangePeriodToDateRange(toggleValue),
            );
            break;
          }

          case DateRangePeriod.AllTime: {
            const range = this.dateHelper.convertDateRangePeriodToDateRange(
              DateRangePeriod.AllTime,
            );
            this.setRangeAndUpdateView({ start: this.minDate || range.start, end: range.end });
            break;
          }
        }

        this.setPage(toggleValue);
      });
  }

  private listenToCalendarOpen(): void {
    this.datepicker.openedStream
      .pipe(
        tap(() => {
          this.toggleControl.setValue(this.inputState.dateRangePeriod$.value || this.defaultPeriod);
          this.resetPage();
        }),
        switchMap(() => this.inputState.calendar$),
        filter((calendar) => !!calendar),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const range = this.inputState.calendar$.value.selected as DateRange<Date>;

        this.rangeGroup.patchValue({
          start: range.start,
          end: range.end,
        });
      });
  }

  private listenToCalendarClose(): void {
    this.datepicker.closedStream.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.selectedDatesPair = [];
    });
  }

  private listenToCalendarChangeByCellClick(): void {
    this.datepicker.openedStream
      .pipe(
        switchMap(() => this.inputState.calendar$),
        filter((calendar) => !!calendar),
        switchMap((calendar) => calendar.selectedChange),
        takeUntil(this.destroy$),
      )
      .subscribe((date) => {
        this.toggleControl.setValue(null, { emitEvent: false });

        this.selectedDatesPair.push(date);

        if (this.selectedDatesPair.length === 2) {
          if (this.dateHelper.isBefore(this.selectedDatesPair[0], this.selectedDatesPair[1])) {
            this.range = { start: this.selectedDatesPair[0], end: this.selectedDatesPair[1] };
            this.selectedDatesPair = [];
          } else {
            this.selectedDatesPair.shift();
          }
        }
      });
  }

  private applyRangeToInputAndCloseCalendar(): void {
    this.inputState.dateRangeGroup.patchValue({ start: null, end: null });

    this.datepicker.select(this.range.start);
    this.datepicker.select(this.range.end);

    this.datepicker.close();
    this.closeEvent.next(this.range);
    this.inputState.dateRangePeriod$.next(this.toggleControl.value);
  }

  public _onApplyClick(): void {
    this.applyRangeToInputAndCloseCalendar();
  }

  public _onCancelClick(): void {
    this.datepicker.close();
    this.closeEvent.next();
  }

  public _onApplyTimeClick(): void {
    this.page = this.previousPage;
  }

  public _onResetTimeClick(): void {
    this.page = this.previousPage;

    this.range = {
      start: this.dateHelper.resetTime(this.range.start),
      end: this.dateHelper.resetTime(this.range.end),
    };
  }

  public _onEditTimeClick(): void {
    this.previousPage = this.page;
    this.page = DatepickerPage.TimeEditor;
  }
}
