import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DndDirective } from './dnd.directive';
import { FileListComponent } from './file-list/file-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { TooltipModule } from '@ui/tooltip/tooltip.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UploaderComponent } from './uploader/uploader.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { SvgModule } from '@ui/svg/svg.module';

@NgModule({
  declarations: [UploaderComponent, FileUploaderComponent, DndDirective, FileListComponent],
  exports: [UploaderComponent, FileUploaderComponent, DndDirective, FileListComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SvgModule,
    ButtonsModule,
    TooltipModule,
  ],
})
export class UploaderModule {}
