import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { RoleState } from '@shared/states/role.state';
import { TranslateService } from '@shared/translates/translate.service';
import { Authority } from '@shared/types/authority';
import { LayoutState } from 'app/layout/layout.state';
import { map } from 'rxjs';

export const SubscriptionCanMatchGuard: CanMatchFn = (route) => {
  const roleState = inject(RoleState);
  const layoutState = inject(LayoutState);
  const translateService = inject(TranslateService);
  const bySubscription = route.data?.bySubscription as boolean;
  const authorities = route.data?.authorities as Authority[];
  const pageTitle = route.data?.pageTitle as string;

  let hasAccessToPage = true;

  if (authorities?.length) {
    if (!roleState.hasAuthorities(authorities, 'one_of')) {
      hasAccessToPage = false;
    }
  }

  if (hasAccessToPage) {
    return true;
  }

  const needSubscription =
    bySubscription &&
    roleState.needSubscription() &&
    roleState.hasDisabledAuthorities(authorities, 'one_of');

  if (needSubscription) {
    return translateService.get$(pageTitle).pipe(
      map((title) => {
        layoutState.subscriptionNotificationData$.next({ title });
        return !needSubscription;
      }),
    );
  }

  return !needSubscription;
};
