import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { IndexDb } from './index-db';

/** store collections */
export enum StoreCollections {
  Tables = 'tables',
  TableViews = 'table-views',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStore {
  private readonly StoreDbName = 'app-store-db';
  private readonly StoreDbVersion = 1; // increment if add collections / update collection name

  private readonly db = new IndexDb();

  constructor() {
    // eslint-disable-next-line no-console
    console.log('%cLocalStore ctor', 'color: gray');
  }

  public init(): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('%cLocalStore init', 'color: gray');

    return this.db.init(this.StoreDbName, this.StoreDbVersion, [
      StoreCollections.Tables,
      StoreCollections.TableViews,
    ]);
  }

  public get<T>(collection: StoreCollections, key: string): Observable<T> {
    return from(this.db.get<T>(collection, key));
  }

  public set<T>(collection: StoreCollections, key: string, value: T): Observable<void> {
    return from(this.db.set<T>(collection, key, value));
  }

  public delete(collection: StoreCollections, key: string): Observable<void> {
    return from(this.db.delete(collection, key));
  }
}
