import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  ElementRef,
  AfterViewInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { takeUntil, tap, switchMap } from 'rxjs/operators';
import { MatTooltip } from '@angular/material/tooltip';
import { getElementRect } from '@shared/helpers/coordinate.helper';

@Component({
  selector: 'app-clipped-text-tooltip, [appClippedTextTooltip]',
  templateUrl: './clipped-text-tooltip.component.html',
  styleUrls: ['./clipped-text-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-clipped-text-tooltip',
  },
})
export class ClippedTextTooltipComponent extends BaseObject implements AfterViewInit {
  @Input('appClippedTextTooltip') public tooltip: string | string[];

  @ViewChild('label') public labelElement: ElementRef<HTMLElement>;
  @ViewChild('labelTest') public labelTestElement: ElementRef<HTMLElement>;
  @ViewChild(MatTooltip) public matTooltip: MatTooltip;

  constructor(
    private el: ElementRef<HTMLElement>,
    private zone: NgZone,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.listenToMouse();
    });
  }

  private listenToMouse(): void {
    fromEvent(this.labelElement.nativeElement, 'mouseenter')
      .pipe(
        tap(() => this.showTooltipIfRequired()),
        switchMap(() => fromEvent(this.labelElement.nativeElement, 'mouseleave')),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.labelTestElement.nativeElement.textContent = null;

        this.zone.run(() => {
          this.matTooltip.message = undefined;
          this.matTooltip.hide();
        });
      });
  }

  private showTooltipIfRequired(): void {
    const labelRect = getElementRect(this.labelElement.nativeElement);
    const hostRect = getElementRect(this.el.nativeElement);

    if (labelRect.width < hostRect.width) {
      this.matTooltip.message = undefined;
      this.matTooltip.hide();
    } else {
      this.labelTestElement.nativeElement.textContent = Array.isArray(this.tooltip)
        ? this.tooltip.join(', ')
        : this.tooltip;
      const labelTestRect = getElementRect(this.labelTestElement.nativeElement);

      if (labelTestRect.width > hostRect.width) {
        this.zone.run(() => {
          this.matTooltip.message = Array.isArray(this.tooltip)
            ? this.tooltip.join('\n')
            : this.tooltip;

          this.matTooltip.show();
        });
      }
    }
  }
}
