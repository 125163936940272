import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClippedTextTooltipComponent } from './clipped-text-tooltip/clipped-text-tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipDirective } from './tooltip/tooltip.directive';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [ClippedTextTooltipComponent, TooltipDirective],
  exports: [ClippedTextTooltipComponent, TooltipDirective],
})
export class TooltipModule {}
