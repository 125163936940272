import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { contentAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import { Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'app-subscription-notification',
  templateUrl: './subscription-notification.component.html',
  styleUrls: ['./subscription-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contentAnimation],
  host: {
    '[class]': 'hostClass',
    '[@contentAnimation]': 'true',
  },
})
export class SubscriptionNotificationComponent extends BaseObject implements OnInit {
  @Input() public title: string;
  @Input() public description: boolean;
  @Input() public hostClass: string;
  @Input() public height: string;
  @Input() public height$: Observable<string>;

  constructor(
    private r: Renderer2,
    private el: ElementRef<HTMLElement>,
  ) {
    super();
  }

  public ngOnInit(): void {
    switch (true) {
      case !!this.height:
        this.r.setStyle(this.el.nativeElement, 'height', this.height);
        break;

      case !!this.height$:
        this.height$.pipe(takeUntil(this.destroy$)).subscribe((height) => {
          this.r.setStyle(this.el.nativeElement, 'height', height);
        });
        break;
    }
  }
}
