import { Injectable } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { SettingsState, SiteName } from '@shared/states/settings.state';

export interface SeoRouteData {
  pageTitle: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private siteTitle: string;

  constructor(
    private title: Title,
    private meta: Meta,
    private settingsState: SettingsState,
  ) {
    this.settingsState.siteName$.subscribe((siteName) => {
      this.siteTitle = siteName === SiteName.Qsf ? 'Queen Street Finance' : 'Luna Wealth';
      this.applyGlobalSeoData();
    });
  }

  private applyGlobalSeoData(): void {
    this.setPageTitle(this.siteTitle);

    this.setMetaTags([
      {
        property: 'og:site_name',
        content: this.siteTitle,
      },
      {
        property: 'og:type',
        content: 'website',
      },
    ]);
  }

  public setDefaultPageTitle(): void {
    this.setPageTitle(this.siteTitle);
  }

  public setPageTitle(title: string): void {
    this.title.setTitle(`${this.siteTitle}: ${title}`);
  }

  public setMetaTags(tags: MetaDefinition[]): void {
    tags.forEach((tag) => this.meta.updateTag(tag));
  }
}
