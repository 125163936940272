import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NotificationType } from '../notifications.types';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-toast',
  },
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0.2 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ToastComponent extends Toast implements OnInit {
  public type: NotificationType;

  @HostBinding('class.app-toast--progress')
  public get isProgressType(): boolean {
    return this.type === NotificationType.Progress;
  }

  public get isSuccessType(): boolean {
    return this.type === NotificationType.Success;
  }

  public get isErrorType(): boolean {
    return this.type === NotificationType.Error;
  }

  public isBodyVisible = false;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  public ngOnInit(): void {
    this.type = this.toastPackage.toastType as NotificationType;
  }

  public _onClose(): void {
    this.toastrService.remove(this.toastPackage.toastId);
  }

  public _onBodyVisibleChange(): void {
    this.isBodyVisible = !this.isBodyVisible;
  }
}
