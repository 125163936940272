import { Directive, Input, TemplateRef } from '@angular/core';
import { SelectComponent } from './select.component';

export interface SelectMultiValueDefContext<D> {
  $implicit: D[];
  removeOptionFunction: (item: D) => void;
}

@Directive({
  selector: '[appSelectMultiValueDef]',
})
export class SelectMultiValueDefDirective<D> {
  // (Optional) for select options typing inside template.
  // Get current option type from given select component and set the type to SelectMultiValueDefContext
  @Input('appSelectMultiValueDefFor') public for: SelectComponent<D>;

  constructor(public template: TemplateRef<SelectMultiValueDefContext<D>>) {}

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard<D>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: SelectMultiValueDefDirective<D>,
    ctx: unknown,
  ): ctx is SelectMultiValueDefContext<D> {
    return true;
  }
}
