import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';
import { SettingsState } from '@shared/states/settings.state';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { BrowserInfoService } from '@shared/helpers/browser-info.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ManagerAuthResponse } from '@shared/dto/gateway-public/models';
import { NotificationsService } from 'app/notifications/notifications.service';
import { AppInjector } from 'app/config/app-injector';
import { ScreenRef } from '@shared/helpers/screen-ref';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { RerdirectService } from '@shared/helpers/redirect.service';

export const TOKEN_CHECK_INTERVAL_TIME_SEC = 5;
export const TOKEN_EXPIRATION_BEFORE_SEC = 60;

export interface AuthData {
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _loggedIn$ = new BehaviorSubject<boolean>(!!localStorage.getItem('token'));
  public loggedOut$ = new Subject<void>();
  private jwtHelperService = new JwtHelperService();

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private settingsState: SettingsState,
    private browserInfoService: BrowserInfoService,
    private rerdirectService: RerdirectService,
    private screenRef: ScreenRef,
    private dateHelper: DateHelper,
  ) {
    this.runCheckTokenLoop();
  }

  public set sessionInFinalCountdown(value: boolean) {
    localStorage.setItem(LocalStorageConstants.SessionInFinalCountdown, `${value}`);
  }

  public get sessionInFinalCountdown(): boolean {
    return localStorage.getItem(LocalStorageConstants.SessionInFinalCountdown) === 'true';
  }

  public setLoggedIn(value: boolean): void {
    if (this._loggedIn$.value !== value) {
      this._loggedIn$.next(value);
    }
  }

  public get loggedIn(): boolean {
    return this._loggedIn$.value;
  }

  public get loggedIn$(): Observable<boolean> {
    return this._loggedIn$.asObservable();
  }

  public login(authData: AuthData): Observable<ManagerAuthResponse> {
    const userClient = this.browserInfoService.browserInfo;
    const params = new HttpParams()
      .append('os', userClient.os)
      .append('isMobile', String(userClient.mobile))
      .append('browser', userClient.browser)
      .append('timezone', this.getUTCTimezone(new Date().getTimezoneOffset()))
      .append('screenSize', userClient.screenSize)
      .append('clientApplicationType', 'WEB');

    return this.http
      .post<ManagerAuthResponse>(this.settingsState.apiPath + '/auth/cookie', authData, {
        observe: 'response',
        params,
        headers: {
          Authorization: 'Basic ' + btoa(`${authData.username.trim()}:${authData.password}`),
        },
      })
      .pipe(
        map((res) => {
          const data = res.body;

          this.clearStorage();
          localStorage.setItem(LocalStorageConstants.Token, data.token);
          localStorage.setItem(LocalStorageConstants.Name, data.name);
          localStorage.setItem(LocalStorageConstants.UserName, data.username?.trim());
          localStorage.setItem(LocalStorageConstants.Email, data.email?.trim());
          localStorage.setItem(LocalStorageConstants.UserId, data.managerId);
          localStorage.setItem(LocalStorageConstants.Authorities, JSON.stringify(data.authorities));
          localStorage.setItem(LocalStorageConstants.Role, JSON.stringify(data.role));

          const serverVersion = res.headers.get('x-app-version');

          if (serverVersion && serverVersion !== this.settingsState.version) {
            this.settingsState.version = serverVersion;
          }

          return data;
        }),
      );
  }

  public logout(withReload: boolean = false): void {
    this.setLoggedIn(false);

    this.http
      .post(this.settingsState.apiPath + '/session-logout', null)
      .pipe(
        map(() => null),
        catchError(() => of(null)),
        finalize(() => {
          this.clearStorage();
        }),
      )
      .subscribe();

    this.loggedOut$.next();

    this.clearStorage();
    this.rerdirectService.goToLoginPage(withReload);
  }

  private runCheckTokenLoop(): void {
    this.zone.runOutsideAngular(() => {
      interval(TOKEN_CHECK_INTERVAL_TIME_SEC * 1000)
        .pipe(filter(() => this.loggedIn && this.screenRef.visible$.value))
        .subscribe(() => this.zone.run(() => this.openSessionExpirationPopupIfRequired()));
    });
  }

  public validate(): Observable<boolean> {
    const token = localStorage.getItem(LocalStorageConstants.Token);

    if (!token) {
      return of(false);
    }

    const valid = this.tokenIsValid();

    if (!valid) {
      return this.refreshToken().pipe(tap((result) => this.setLoggedIn(result)));
    } else {
      this.setLoggedIn(true);
      return of(true);
    }
  }

  private openSessionExpirationPopupIfRequired(): void {
    const token = localStorage.getItem(LocalStorageConstants.Token);
    const isTokenExpired = this.jwtHelperService.isTokenExpired(token);

    if (!token || isTokenExpired) {
      this.setLoggedIn(false);
      this.logout(true);
      return;
    }

    if (this.sessionInFinalCountdown) {
      return;
    }

    const tokenExpirationDate = this.jwtHelperService.getTokenExpirationDate(token);
    const timeNow = new Date();
    const timeForExpirationPopupShow = this.dateHelper.sub(
      tokenExpirationDate,
      TOKEN_EXPIRATION_BEFORE_SEC,
      'Seconds',
    );

    if (this.dateHelper.isAfterOrEqual(timeNow, timeForExpirationPopupShow)) {
      AppInjector.Injector.get(NotificationsService)
        .openSessionExpirationDialog()
        .subscribe((continueWork) => {
          if (continueWork) {
            this.refreshToken().subscribe((valid) => {
              this.setLoggedIn(valid);

              if (!valid) {
                this.logout(true);
              }
            });
          }
        });
    }
  }

  private tokenIsValid(): boolean {
    try {
      const token = localStorage.getItem(LocalStorageConstants.Token);
      const valid = !this.jwtHelperService.isTokenExpired(token);

      return valid;
    } catch (e) {
      return false;
    }
  }

  public clearStorage(): void {
    const startPage = this.rerdirectService.startPage;
    const currentTheme = localStorage.getItem(LocalStorageConstants.CurrentTheme);

    localStorage.clear();

    this.rerdirectService.startPage = startPage;
    localStorage.setItem(LocalStorageConstants.CurrentTheme, currentTheme);
  }

  private refreshToken(): Observable<boolean> {
    return this.http
      .put(this.settingsState.apiPath + '/auth/cookie', null, {
        responseType: 'text',
      })
      .pipe(
        tap((token) => localStorage.setItem(LocalStorageConstants.Token, token)),
        map((token) => !!token),
        catchError(() => of(false)),
      );
  }

  private getUTCTimezone(offset: number): string {
    // get timezone in hours and invert.
    // By default getTimeZoneOffset returns -180 for UTC +3 and vice versa
    return String((offset / 60) * -1);
  }
}
