import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { UserSettingsState } from '@shared/states/user-settings.state';
import { EMPTY_REPLACER } from '@shared/constants/table/grid-constants';
import { AppInjector } from 'app/config/app-injector';
import { THOUSAND_SEPARATOR } from '@shared/types/preferences';
import { isNull } from '@shared/base/core';

export const ROUND_DECIMALS_CONSTANTS = new Map<number, number>([
  [0, 1],
  [1, 10],
  [2, 100],
  [3, 1000],
  [4, 10000],
  [5, 100000],
  [6, 1000000],
]);

export const ROUND_DIGITS_INFO_CONSTANTS = new Map<number, string>([
  [0, '1.0-0'],
  [1, '1.0-1'],
  [2, '1.0-2'],
  [3, '1.0-3'],
  [4, '1.0-4'],
  [5, '1.0-5'],
  [6, '1.0-6'],
]);

export function GetNumberWithoutRound(value: number, decimalsCount: number): number {
  const n = ROUND_DECIMALS_CONSTANTS.get(decimalsCount);
  return Math.floor((value + Number.EPSILON) * n) / n;
}

export function GetNumberWithRound(value: number, decimalsCount: number): number {
  const n1 = ROUND_DECIMALS_CONSTANTS.get(decimalsCount);
  const n2 = ROUND_DECIMALS_CONSTANTS.get(decimalsCount + 1);

  let result = Number((Math.round((value + Number.EPSILON) * n2) / n2).toFixed(decimalsCount + 1));
  result = Number((Math.round((result + Number.EPSILON) * n1) / n1).toFixed(decimalsCount));

  return result;
}

export function GetNumberWithRoundV2(value: number, decimalsCount: number): number {
  const n1 = ROUND_DECIMALS_CONSTANTS.get(decimalsCount);

  const result = Number((Math.round((value + Number.EPSILON) * n1) / n1).toFixed(decimalsCount));

  return result;
}

export function GetSignLiteral(value: number, showSign: boolean): string {
  if (value < 0) {
    return '-';
  } else if (!showSign || !value) {
    return '';
  }

  return '+';
}

@Pipe({
  name: 'appNumber',
})
export class NumberPipe implements PipeTransform {
  constructor() {}

  public transform(
    value: number,
    showSign: boolean = false,
    decimalsCount?: number,
    trailingZeros?: boolean,
  ): string {
    if (!isFinite(value)) {
      return EMPTY_REPLACER;
    }

    const userSettingsState = AppInjector.Injector.get(UserSettingsState);
    const _decimalsCount = isNull(decimalsCount)
      ? userSettingsState.preferences.decimalsCount
      : decimalsCount;

    value = GetNumberWithRound(value, _decimalsCount);

    const valueStr = formatNumber(
      value,
      'en-US',
      trailingZeros ? `1.${_decimalsCount}-${_decimalsCount}` : `1.0-${_decimalsCount}`,
    );

    let res = (valueStr || '0').split(',').join(THOUSAND_SEPARATOR).replace('-', '');

    const signLiteral = GetSignLiteral(value, showSign);

    res = signLiteral + res;

    return res;
  }
}
