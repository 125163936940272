import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appLog',
})
export class LogPipe implements PipeTransform {
  public transform<T>(value: T, name?: string): T {
    // eslint-disable-next-line no-console
    console.log(`appLog ${name ? '[' + name + ']' : ''}:\n${JSON.stringify(value, null, ' ')}`);
    return value;
  }
}
