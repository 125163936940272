import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { RerdirectService } from '@shared/helpers/redirect.service';
import { SiteInfoService } from '@api/site-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-footer',
  },
})
export class FooterComponent {
  public copyrightData$ = this.siteInfoService.getByType('COPYRIGHT');

  constructor(
    private siteInfoService: SiteInfoService,
    private rerdirectService: RerdirectService,
  ) {}

  public goToTermsOfUse(): void {
    this.rerdirectService.goToTermsOfUse();
  }

  public onMouseupToTermsOfUse(e: MouseEvent): void {
    if (e.button === 1) {
      this.rerdirectService.goToTermsOfUse();
    }
  }

  public goToCookiePolicy(): void {
    this.rerdirectService.goToCookiePolicy();
  }

  public onMouseupToCookiePolicy(e: MouseEvent): void {
    if (e.button === 1) {
      this.rerdirectService.goToCookiePolicy();
    }
  }

  public goToPrivacyPolicy(): void {
    this.rerdirectService.goToPrivacyPolicy();
  }

  public onMouseupToPrivacyPolicy(e: MouseEvent): void {
    if (e.button === 1) {
      this.rerdirectService.goToPrivacyPolicy();
    }
  }
}
