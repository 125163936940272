import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { listItemAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectRef } from './select-ref';
import { SELECT_OPTION, SelectOption } from './select.types';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [listItemAnimation],
  host: {
    class: 'app-select-option',
    '[@listItemAnimation]': 'true',
  },
})
export class SelectOptionComponent extends BaseObject {
  @Input()
  public set disabled(value: boolean) {
    this.option.disabled = value;
  }

  @HostBinding('class.app-select-option--disabled')
  public get _disabled(): boolean {
    return this.option.disabled;
  }

  @HostBinding('class.app-select-option--selected')
  public get selected(): boolean {
    return this.option.selected;
  }

  constructor(
    public selectRef: SelectRef,
    private cd: ChangeDetectorRef,
    @Inject(SELECT_OPTION) private option: SelectOption,
  ) {
    super();

    if (this.selectRef.component.multiple) {
      merge(this.selectRef.component.addOptionEvent$, this.selectRef.component.removeOptionEvent$)
        .pipe(takeUntil(this.destroy$))
        .subscribe((option) => {
          if (option === this.option.value) {
            this.cd.markForCheck();
          }
        });
    }
  }
}
