import { CanDeactivateFn } from '@angular/router';
import { of } from 'rxjs';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';

export interface CleanLocalStorageRouteData {
  items: LocalStorageConstants[];
}

export const CleanLocalStorageCanDeactivateGuard: CanDeactivateFn<any> = (_, currentRoute) => {
  const keys = (currentRoute.data?.cleanLocalStorage as CleanLocalStorageRouteData)?.items;

  if (keys?.length) {
    for (const key of keys) {
      localStorage.removeItem(key);
    }
  }

  return of(true);
};
