import { Directive, Input, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectComponent } from './select.component';
import { SelectOption } from './select.types';

export interface SelectContext {
  selected$: BehaviorSubject<SelectOption>;
}

export interface SelectGroupDefContext<GroupType> {
  $implicit: GroupType;
}

@Directive({
  selector: '[appSelectGroupDef]',
})
export class SelectGroupDefDirective<D, GroupType> {
  // (Optional) for select options typing inside template.
  // Get current option type from given select component and set the type to SelectGroupDefContext
  @Input('appSelectGroupDefFor') public for: SelectComponent<D, GroupType>;

  constructor(public template: TemplateRef<SelectGroupDefContext<GroupType>>) {}

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard<D, GroupType>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: SelectGroupDefDirective<D, GroupType>,
    ctx: unknown,
  ): ctx is SelectGroupDefContext<GroupType> {
    return true;
  }
}
