import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { FileData } from '../uploader.types';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FileListComponent {
  @HostBinding('class.app-file-list') public readonly hostClass = true;
  @HostBinding('class.app-border-dashed') public readonly borderClass = true;

  @Input()
  public set files(value: FileData<any>[]) {
    this.files$.next(value);
  }

  @Input() public label: string = 'Drag and drop or select file';
  @Input() public showLabel: boolean = true;
  @Input() public multiple: boolean = true;
  @Input() public format: 'file' | 'base64' = 'file';
  @Input() public accept: string;
  @Input() public showButton: boolean = true;
  @Input() public buttonText: string = 'Add Files';
  @Input() public disabled: boolean = false;
  @Input() public canEditName: boolean = true;
  @Input() public backgroundTemplate: TemplateRef<unknown>;
  @Input() public set hideBackground(value: boolean) {
    this.hideBackground$.next(value);
  }

  @Output() public readonly changeFiles = new EventEmitter<FileData<any>[]>();

  public nameControl = new UntypedFormControl();
  public editableIndex: number;
  public files$ = new BehaviorSubject<FileData<any>[]>([]);
  public hideBackground$ = new BehaviorSubject<boolean>(false);

  @ViewChild(FileUploaderComponent) public fileUploader: FileUploaderComponent;

  constructor() {}

  public trackByIndex(index: number): number {
    return index;
  }

  public onStartEdit(event: MouseEvent, index: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.editableIndex = index;
    this.nameControl.patchValue(this.files$.value[index].name);
  }

  public onSuccessEdit(event: MouseEvent, index: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.editableIndex = undefined;
    const newArr = [...this.files$.value];
    newArr[index] = { ...newArr[index], name: this.nameControl.value };
    this.changeFiles.emit(newArr);
  }

  public onDeclineEdit(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.editableIndex = undefined;
  }

  public onDeleteFile(event: MouseEvent, index: number): void {
    event.preventDefault();
    event.stopPropagation();

    const file: FileData<any> = this.files$.value[index];
    const newFiles = this.files$.value.reduce<FileData<any>[]>((res, item) => {
      if (item !== file) {
        res.push(item);
      }
      return res;
    }, []);

    this.fileUploader.setFiles(newFiles);
    this.changeFiles.emit(newFiles);
  }

  public onChangeData(files: FileData<any>[]): void {
    if (this.disabled) {
      return;
    }

    if (this.accept) {
      const validFiles: FileData<any>[] = [];

      for (const file of files) {
        if (this.accept.includes(this.getFileExtensionFromName(file.name))) {
          validFiles.push(file);
        }
      }

      this.files$.next(this.multiple ? [...this.files$.value, ...validFiles] : validFiles);
    } else {
      this.files$.next(this.multiple ? [...this.files$.value, ...files] : files);
    }

    this.changeFiles.emit(this.files$.value);
  }

  public onDndClick(event: MouseEvent): void {
    if ((event.target as HTMLElement).tagName !== 'INPUT') {
      this.fileUploader?.click();
    }
  }

  private getFileExtensionFromName(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  }
}
