import { Directive, Input, TemplateRef } from '@angular/core';
import { SelectComponent } from './select.component';

export interface SelectValueDefContext<D> {
  $implicit: D;
}

@Directive({
  selector: '[appSelectValueDef]',
})
export class SelectValueDefDirective<D> {
  // (Optional) for select options typing inside template.
  // Get current option type from given select component and set the type to SelectValueDefContext
  @Input('appSelectValueDefFor') public for: SelectComponent<D>;

  constructor(public template: TemplateRef<SelectValueDefContext<D>>) {}

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard<D>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: SelectValueDefDirective<D>,
    ctx: unknown,
  ): ctx is SelectValueDefContext<D> {
    return true;
  }
}
