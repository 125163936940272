import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipe } from './number.pipe';
import { UserSettingsState } from '@shared/states/user-settings.state';
import { getCurrencySymbol } from '@angular/common';
import { AppInjector } from 'app/config/app-injector';
import { EMPTY_REPLACER } from '@shared/constants/table/grid-constants';
import { isNotNull } from '@shared/base/core';
import { GetThousandValueWithSuffix } from './get-thousand-value-with-suffix.pipe';

@Pipe({
  name: 'appCcyWithThousandSuffix',
})
export class CcyWithThousandSuffixPipe implements PipeTransform {
  private NUMBER_PIPE = new NumberPipe();

  constructor() {}

  public transform(
    value: number,
    decimalsCount?: number,
    showCcy: boolean = true,
    ccy?: string,
  ): string {
    if (isNaN(value)) {
      return EMPTY_REPLACER;
    }

    const userSettingsState = AppInjector.Injector.get(UserSettingsState);
    decimalsCount = isNotNull(decimalsCount)
      ? decimalsCount
      : userSettingsState.preferences.decimalsCount;

    const { thousandValue, suffix } = GetThousandValueWithSuffix(value);

    let res = `${this.NUMBER_PIPE.transform(thousandValue, false, decimalsCount)}${suffix}`;

    if (showCcy) {
      const currency = ccy || userSettingsState.preferences.currency;

      if (value < 0 && res.indexOf('-') === 0) {
        res = `-${getCurrencySymbol(currency, 'narrow') || '$'}${res.slice(1)}`;
      } else {
        res = `${getCurrencySymbol(currency, 'narrow') || '$'}${res}`;
      }
    }

    return res;
  }
}
