import { Injectable } from '@angular/core';
import { SettingsState } from '@shared/states/settings.state';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LazyData } from '@ui/table/table-types';
import {
  ClientDTO,
  ExcelTableRequestDTO,
  SearchRequestDTO,
  UsageDTO,
} from '@shared/dto/accounts/models';
import { FileLoaderService } from '@shared/helpers/file-loader.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  protected readonly entryPoint = '/clients';

  constructor(
    private settingsState: SettingsState,
    private fileLoaderService: FileLoaderService,
    private http: HttpClient,
  ) {}

  public getClientsWithPagination(
    request: SearchRequestDTO,
    withDeleted: boolean = false,
  ): Observable<LazyData<ClientDTO>> {
    let url = `${this.settingsState.apiPath}${this.entryPoint}`;

    url += '/search';

    return this.http
      .post<ClientDTO[]>(url, request, {
        observe: 'response',
        params: {
          withDeleted: `${withDeleted}`,
        },
      })
      .pipe(
        map((res) => {
          return {
            rows: res.body,
            rowCount: Number.parseInt(res.headers.get('X-Total-Count'), 10),
          } as LazyData<ClientDTO>;
        }),
        catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData<ClientDTO>)),
      );
  }

  public getAll(withDeleted: boolean = false): Observable<ClientDTO[]> {
    return this.http
      .get<ClientDTO[]>(`${this.settingsState.apiPath}${this.entryPoint}`, {
        params: {
          withDeleted: `${withDeleted}`,
        },
      })
      .pipe(catchError(() => of([])));
  }

  public getClientsByAccount(id: string): Observable<ClientDTO[]> {
    const url = `${this.settingsState.apiPath}${this.entryPoint}/accounts/${id}`;
    return this.http
      .get<ClientDTO[]>(url)
      .pipe(catchError((error) => throwError(() => error || new Error('getUsersByAccount error'))));
  }

  public getOne(id: string): Observable<ClientDTO> {
    return this.http
      .get<ClientDTO>(`${this.settingsState.apiPath}/clients/${id}`)
      .pipe(catchError(() => of(null)));
  }

  public update(client: ClientDTO): Observable<ClientDTO> {
    return this.http
      .put<ClientDTO>(`${this.settingsState.apiPath}/clients`, client)
      .pipe(catchError(() => of(null)));
  }

  public create(client: ClientDTO): Observable<ClientDTO> {
    return this.http
      .post<ClientDTO>(`${this.settingsState.apiPath}/clients`, client)
      .pipe(catchError(() => of(null)));
  }

  public getUsage(): Observable<UsageDTO> {
    return this.http
      .get<UsageDTO>(`${this.settingsState.apiPath}/clients/usage`)
      .pipe(catchError(() => of(null)));
  }

  public loadExcel(
    fileName: string,
    searchRequest: ExcelTableRequestDTO,
    withDeleted: boolean = false,
  ): void {
    const url = `${this.settingsState.apiPath}/clients/generate-excel-table`;

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName, {
      withDeleted: `${withDeleted}`,
    });
  }
}
