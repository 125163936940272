import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PushNotificationDTO } from '@shared/dto/notification/models';
import { DialogService } from '@ui/dialog/dialog.service';
import { OrderExecuteDialogFactory } from 'app/dialogs/order/order-execute/order-execute-dialog.factory';
import {
  OrderExecuteDialogData,
  OrderExecuteDialogResult,
} from 'app/dialogs/order/order-execute/order-execute.types';
import { UiPushNotificationDTO } from '../dialogs/notifications/notifications-dialog.types';

@Injectable({
  providedIn: 'root',
})
export class NotificationActionsService {
  constructor(
    private dialogService: DialogService,
    private router: Router,
  ) {}

  private allowedToShowOrderNotificationTypes: PushNotificationDTO.TypeEnum[] = [
    'orderDVP',
    'orderReady',
    'editParameters',
    'orderClientApprove',
    'fixOrderFilled',
    'orderHidApprove',
    'fixOrderRejected',
    'orderClientApprove',
    'editParametersChangeStatus',
  ];

  public execute(
    notificationParams: PushNotificationDTO['params'],
    type: PushNotificationDTO.TypeEnum,
  ): void {
    if (this.allowedToShowOrderNotificationTypes.find((t) => t === type)) {
      this.showOrder(notificationParams['orderId']);
    }
  }

  public executeEntity(
    _entity: UiPushNotificationDTO['params']['entityType'],
    id: UiPushNotificationDTO['params']['id'],
  ): Observable<boolean> {
    if (!_entity || (!id && _entity && _entity !== 'REPORT')) {
      return of(false);
    }

    let entity: string = _entity.toLowerCase().replace(/_/gi, '-');
    let params = {
      id: id,
    };

    switch (_entity) {
      case 'ACCOUNT_COMPANY':
        entity = 'company';
        break;

      case 'ADDRESS':
      case 'CLIENT_REQUEST_OPPORTUNITY':
      case 'LEAD_OPPORTUNITY':
      case 'PRODUCT':
        entity = null;
        break;

      case 'MANDATE_RISK_PROFILE':
        entity = 'risk-profile';
        break;
    }

    let url = `/detail/${entity}`;

    switch (_entity) {
      case 'PORTFOLIO':
        url = `/portfolios/all/${id}/profile`;
        params = null;
        break;
      case 'REPORT':
        url = `/reports/client-reports`;
        params = null;
        break;
    }

    if (!entity || (!id && entity && entity !== 'REPORT')) {
      of(false);
    }

    this.router.navigate([url], { queryParams: params });

    return of(true);
  }

  private showOrder(id: string): void {
    this.dialogService.openLazy<OrderExecuteDialogData, OrderExecuteDialogResult>(
      { factory: OrderExecuteDialogFactory },
      { id },
    );
  }
}
