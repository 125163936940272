import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';
import { contentAnimation } from '@shared/animations/animations';
import { SiteInfoDTO } from '@shared/dto/accounts/models';

@Component({
  selector: 'app-site-info-outlet',
  templateUrl: './site-info-outlet.component.html',
  styleUrls: ['./site-info-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [contentAnimation],
  host: {
    class: 'app-site-info-outlet',
  },
})
export class SiteInfoOutletComponent {
  @Input() public data: SiteInfoDTO;
  @Input() public showExpander: boolean = false;

  public expanded = false;

  @HostBinding('class.app-site-info-outlet--expanded')
  public get _expanded(): boolean {
    return this.showExpander && this.expanded;
  }

  constructor() {}
}
