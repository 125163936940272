<div class="app-spinner__container"
     [ngStyle]="{ 'width': size, 'height': size, 'margin': _margin }">
  <app-svg type="none"
           [ngStyle]="{ 'width': size, 'height': size }">
    <svg width="48"
         height="48"
         viewBox="0 0 48 48"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM24.5106 3.06383C35.7913 3.06383 44.9362 12.2087 44.9362 23.4894C44.9362 34.7701 35.7913 43.9149 24.5106 43.9149C13.2299 43.9149 4.08511 34.7701 4.08511 23.4894C4.08511 12.2087 13.2299 3.06383 24.5106 3.06383Z"
            fill="url(#app-spinner-gradient)" />
      <defs>
        <linearGradient id="app-spinner-gradient"
                        x1="0"
                        y1="38.9959"
                        x2="29.9918"
                        y2="38.9959"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="1"
                stop-color="white"
                stop-opacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  </app-svg>
</div>