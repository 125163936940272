<div class="app-dialog-notification">
  <div class="app-dialog-notification__header">
    <app-svg class="app-dialog-notification__icon"
             [icon]="'AttentionSquare'"
             [disabledHover]="true"
             subType="danger">
    </app-svg>

    <span class="heading-4-special">
      {{ data.title }}
    </span>

    <app-svg [icon]="'Close_24'"
             (click)="_onCloseClick()"
             class="app-dialog-notification__close">
    </app-svg>
  </div>

  <div class="app-dialog-notification__body">
    <span class="subtitle-2"
          style="padding-top: 5px;">
      {{ data.description }}
    </span>

    <mat-expansion-panel *ngIf="data.error"
                         class="w-100"
                         [hideToggle]="true"
                         [expanded]="false"
                         style="margin-top: 10px;"
                         #errorsPanel="matExpansionPanel">
      <mat-expansion-panel-header collapsedHeight="24px"
                                  expandedHeight="24px"
                                  style="padding: 0px 8px;">
        <mat-panel-title>
          <app-svg [icon]="'ArrowMediumRight_24'"
                   class="app-expander-icon"
                   [class.app-expander-icon--down]="errorsPanel.expanded">
          </app-svg>

          <span class="subtitle-4">Show Errors</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div style="display: block; max-height: 400px; overflow: auto;"
           class="app-scrollbar-block">
        <pre style="margin: 0px;">{{ data.error | json }}</pre>
      </div>
    </mat-expansion-panel>
  </div>

  <div class="app-dialog-notification__footer">
    <app-button type="secondary"
                (click)="_onCloseClick()"
                style="margin-right: 10px;">
      {{ 'common.close'|translate }}
    </app-button>
  </div>
</div>