import { Pipe, PipeTransform } from '@angular/core';
import { GlobalTagCut, WrapAllLinesInBr } from '@shared/helpers/text-editor.helper';
import sanitizeHtml from 'sanitize-html';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor() {}
  public transform(value: string): string {
    /**
     * Чистка всех тегов и атрибутов, кроме разрешенных в allowedTags и allowedAttributes
     */
    return sanitizeHtml(WrapAllLinesInBr(GlobalTagCut(value)), {
      allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'a', 'br', 'u', 'ul', 'li'],
      allowedAttributes: {
        a: ['href', 'name', 'target'],
      },
    });
  }
}
