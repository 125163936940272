/**
 * Удаление опасных глобальных тегов вместе с их содержимым
 */
export function GlobalTagCut(str: string): string {
  if (str) {
    const matching = [
      str.match(new RegExp(/<script(.*?)<\/script>/gi)),
      str.match(new RegExp(/&lt;script(.*?)&lt;\/script&gt;/gi)),
      str.match(new RegExp(/<style(.*?)<\/style>/gi)),
      str.match(new RegExp(/&lt;style(.*?)&lt;\/style&gt;/gi)),
      str.match(new RegExp(/<link(.*?)<\/link>/gi)),
      str.match(new RegExp(/&lt;link(.*?)&lt;\/link&gt;/gi)),
      str.match(new RegExp(/<iframe(.*?)<\/iframe>/gi)),
      str.match(new RegExp(/&lt;iframe(.*?)&lt;\/iframe&gt;/gi)),
      str.match(new RegExp(/<applet(.*?)<\/applet>/gi)),
      str.match(new RegExp(/&lt;applet(.*?)&lt;\/applet&gt;/gi)),
      str.match(new RegExp(/<img(.*?)>/gi)),
      str.match(new RegExp(/&lt;img(.*?)&gt;/gi)),
    ];

    matching.forEach((match) => {
      if (match?.length) {
        match.forEach((item) => {
          str = str.replace(item, '');
        });
      }
    });
  }

  return str;
}

export function CleanAllTags(str: string): string {
  return str
    ? str.replace(new RegExp(/( |<([^>]+)>)/gi), '').replace(new RegExp('\r?\n', 'g'), '<br>')
    : null;
}

/**
 * Чтобы при вставки сохранялась блочная структура, в конце каждой строки устанавливается тег <br>
 */
export function WrapAllLinesInBr(str: string): string {
  return str ? str.replace(new RegExp('\r?\n', 'g'), '<br>') : null;
}
