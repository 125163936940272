import { Pipe, PipeTransform } from '@angular/core';
import { isNotNull } from '@shared/base/core';

export function GetThousandValueWithSuffix(value: number): {
  thousandValue: number;
  suffix?: 'K' | 'M' | 'BN' | 'TN' | '';
} {
  if (!isNotNull(value) || Math.abs(value) < 1000) {
    return {
      thousandValue: value,
      suffix: '',
    };
  }

  const K_Value = value / 1000;
  const M_Value = value / 1000000;
  const BN_Value = value / 1000000000;
  const TN_Value = value / 1000000000000;

  const K_ValueAbs = Math.abs(K_Value);
  const M_ValueAbs = Math.abs(M_Value);
  const BN_ValueAbs = Math.abs(BN_Value);
  const TN_ValueAbs = Math.abs(TN_Value);

  let suffix: 'K' | 'M' | 'BN' | 'TN';
  let thousandValue: number;

  switch (true) {
    case K_ValueAbs <= 999: {
      suffix = 'K';
      thousandValue = K_Value;
      break;
    }

    case M_ValueAbs <= 999: {
      suffix = 'M';
      thousandValue = M_Value;
      break;
    }

    case BN_ValueAbs <= 999: {
      suffix = 'BN';
      thousandValue = BN_Value;
      break;
    }

    case TN_ValueAbs <= 999: {
      suffix = 'TN';
      thousandValue = TN_Value;
      break;
    }
  }

  return { thousandValue, suffix };
}

@Pipe({
  name: 'appGetThousandValueWithSuffix',
})
export class GetThousandValueWithSuffixPipe implements PipeTransform {
  constructor() {}

  public transform(value: number): ReturnType<typeof GetThousandValueWithSuffix> {
    return GetThousandValueWithSuffix(value);
  }
}
