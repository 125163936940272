import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { START_PAGE } from '@shared/helpers/redirect.service';

export const DevSiteCanActivateGuard: CanActivateFn = () => {
  return devSiteCheck();
};

function devSiteCheck(): boolean {
  if (environment.isDevSite) {
    return true;
  } else {
    inject(Router).navigate([START_PAGE]);
    return false;
  }
}
