import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { BlobLoaderHelperService } from '@shared/helpers/blob-loader-helper.service';
import { BaseObject } from '@shared/base/base-object';
import { MediaType } from '@shared/constants/media-file.type';

export interface LoadMediaResult {
  loadingState: 'done' | 'in-progress' | 'error';
  objectUrl?: SafeUrl;
}

@Pipe({
  name: 'loadMediaAndGetObjectUrl',
})
export class LoadMediaAndGetObjectUrlPipe extends BaseObject implements PipeTransform {
  private stopLoadingEvent$ = new Subject<void>();

  constructor(
    private sanitizer: DomSanitizer,
    private blobLoaderHelperService: BlobLoaderHelperService,
  ) {
    super();
  }

  public transform(
    url: string | Blob,
    type: MediaType | string = 'image',
  ): Observable<LoadMediaResult> {
    this.stopLoadingEvent$.next();

    return new Observable<LoadMediaResult>((subscriber) => {
      if (url) {
        if (typeof url === 'string') {
          const blobMap = this.blobLoaderHelperService.getBlobMapByRemoteSrc(url);

          subscriber.next({ loadingState: 'in-progress' });

          if (blobMap) {
            subscriber.next({
              loadingState: 'done',
              objectUrl: blobMap.blobSrc,
            });
            subscriber.complete();
          } else {
            if (type === 'video') {
              // TODO после реализации на бэке решить проблему преждевременной загрузки следующего куска,
              //  чтобы видео не скакало, и переключить на потоковую загрузку
              // const blobs: Blob[] = [];
              //
              // this.blobLoaderHelperService
              //   .getVideoPart(url, 0, VIDEO_PART_SIZE)
              //   .subscribe((data) => {
              //     const dataUrl = this.sanitizer.bypassSecurityTrustUrl(
              //       URL.createObjectURL(data.blob),
              //     );
              //
              //     if (data.isLastPart) {
              //       const blobMap = this.blobLoaderHelperService.createBlobMap(
              //         url,
              //         dataUrl,
              //         new Blob(blobs),
              //       );
              //
              //       this.blobLoaderHelperService.addBlobMap(blobMap);
              //
              //       subscriber.next({
              //         loadingState: 'done',
              //         objectUrl: dataUrl,
              //       });
              //       subscriber.complete();
              //     } else {
              //       blobs.push(data.blob);
              //     }
              //
              //     subscriber.next({
              //       loadingState: 'done',
              //       objectUrl: dataUrl,
              //     });
              //   });
              this.blobLoaderHelperService
                .loadBlobFile(url)
                .pipe(
                  map((blob) => {
                    const dataUrl = this.sanitizer.bypassSecurityTrustUrl(
                      URL.createObjectURL(blob),
                    );
                    const blobMap = this.blobLoaderHelperService.createBlobMap(url, dataUrl);

                    this.blobLoaderHelperService.addBlobMap(blobMap);

                    return dataUrl;
                  }),
                  takeUntil(this.stopLoadingEvent$),
                  takeUntil(this.destroy$),
                  catchError(() => of<SafeUrl>(null)),
                )
                .subscribe((data) => {
                  if (data) {
                    subscriber.next({ loadingState: 'done', objectUrl: data });
                    subscriber.complete();
                  } else {
                    subscriber.next({ loadingState: 'error' });
                  }
                });
            } else {
              this.blobLoaderHelperService
                .loadBlobFile(url)
                .pipe(
                  map((blob) => {
                    const dataUrl = this.sanitizer.bypassSecurityTrustUrl(
                      URL.createObjectURL(blob),
                    );
                    const blobMap = this.blobLoaderHelperService.createBlobMap(url, dataUrl);

                    this.blobLoaderHelperService.addBlobMap(blobMap);

                    return dataUrl;
                  }),
                  takeUntil(this.stopLoadingEvent$),
                  takeUntil(this.destroy$),
                  catchError(() => of<SafeUrl>(null)),
                )
                .subscribe((data) => {
                  if (data) {
                    subscriber.next({ loadingState: 'done', objectUrl: data });
                    subscriber.complete();
                  } else {
                    subscriber.next({ loadingState: 'error' });
                  }
                });
            }
          }
        } else {
          subscriber.next({
            loadingState: 'done',
            objectUrl: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(url)),
          });
          subscriber.complete();
        }
      } else {
        subscriber.next({ loadingState: 'error' });
      }
    });
  }
}
