import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback.component';
import { GridModule } from '@ui/grid/grid.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UploaderModule } from '@ui/uploader/uploader.module';
import { TranslateModule } from '@ngx-translate/core';
import { InputModule } from '@ui/input/input.module';
import { SelectModule } from '@ui/select/select.module';
import { SvgModule } from '@ui/svg/svg.module';
import { DialogModule } from '@ui/dialog/dialog.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ButtonsModule,
    GridModule,
    ReactiveFormsModule,
    DirectivesModule,
    InputModule,
    SelectModule,
    ButtonsModule,
    SpinnerModule,
    SvgModule,
    DialogModule,
    MatButtonToggleModule,
    UploaderModule,
  ],
  declarations: [FeedbackComponent],
  exports: [FeedbackComponent],
})
export class FeedbackModule {}
