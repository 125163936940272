import { EntityAliases } from '@shared/constants/entity-aliases';

export function getDetailPageLink(
  alias: EntityAliases,
  params: { [param: string]: any },
  fragment?: string,
): string {
  const url = new URL(`${document.baseURI}detail/${alias}`);

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value);
  }

  if (fragment) {
    url.href += `#${fragment}`;
  }

  return url.href;
}

export function openEntityInPage(
  alias: EntityAliases,
  params: { [param: string]: any },
  fragment?: string,
): void {
  window.open(getDetailPageLink(alias, params, fragment));
}

export function openPortfolioInPage(
  portfolioId: string,
  isClient: boolean,
  params: { [param: string]: any },
): void {
  const url = new URL(
    `${document.baseURI}portfolios/${isClient ? 'client' : 'all'}/${portfolioId}`,
  );

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value);
  }

  window.open(url.href);
}
