import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SettingsState } from '@shared/states/settings.state';
import { LazyData } from '@ui/table/table-types';
import {
  AccountPortfoliosHierarchyDTO,
  AccountPortfoliosHierarchyWithPerformanceDTO,
  AvailableAssetsByBankAccount,
  PortfolioDTO,
  PortfolioPerformanceWrapperDTO,
  PortfolioValuationDTO,
  PositionAvailableAssetsDTO,
  PositionSummaryMobileDTO,
  RecalculatedInstrumentDTO,
  RecalculatedPortfolioDTO,
  IdNameDTO,
  AvailableProductValueDTO,
  BankAccountAvailableAssetsDTO,
  BlockedQtyDTO,
  BankAccountDTO,
  PortfolioAumDTO,
  BankAccountWithPnlDTO,
  PositionChartPerformanceDTO,
  PortfolioOmnibusAvailableAssetsDTO,
  SimpleDateValueDTO,
  PositionSummaryWithPerformanceWidgetsDTO,
} from '@shared/dto/positions/models';
import { ExcelTableRequestDTO, SearchRequestDTO } from '@shared/dto/gateway-secured/models';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { FileLoaderService } from '@shared/helpers/file-loader.service';
import { isNotNaN } from '@shared/base/core';
import { SourceEntityType } from '@shared/types/source-entity-type';
import { DataWithError } from '@shared/types/data-with-error';

@Injectable({
  providedIn: 'root',
})
export class PortfoliosService {
  constructor(
    private http: HttpClient,
    private settingsState: SettingsState,
    private fileLoaderService: FileLoaderService,
    private dateHelper: DateHelper,
  ) {}

  public getAll(): Observable<PortfolioDTO[]> {
    return this.http
      .get<PortfolioDTO[]>(`${this.settingsState.apiPath}/portfolios`)
      .pipe(catchError(() => of([])));
  }

  public getPortfoliosWithPagination<T = PortfolioDTO>(
    request: SearchRequestDTO,
    date: Date | string = null,
    ccy: string = null,
  ): Observable<LazyData<T>> {
    let url = `${this.settingsState.apiPath}/portfolios`;
    const params: { [key: string]: string } = {};

    if (ccy) {
      params['ccy'] = ccy;
    }

    if (date) {
      params['date'] = this.dateHelper.format(date);
    }

    url += '/search';

    return this.http
      .post<T[]>(url, request, {
        params: params,
        observe: 'response',
      })
      .pipe(
        map((res) => {
          return {
            rows: res.body,
            rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
          } as LazyData<T>;
        }),
        catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData<T>)),
      );
  }

  public getConsolidatedPortfolioIdByAccountId(accountId: string): Observable<string> {
    return this.http
      .get<string>(`${this.settingsState.apiPath}/portfolios/consolidated-id/${accountId}`)
      .pipe(catchError(() => of(null)));
  }

  public getPortfolioById(portfolioId: string): Observable<PortfolioDTO> {
    return this.http
      .get<PortfolioDTO>(`${this.settingsState.apiPath}/portfolios/${portfolioId}`)
      .pipe(catchError(() => of(null)));
  }

  public getPortfoliosByAccount(accountId: string): Observable<PortfolioDTO[]> {
    return this.http
      .get<PortfolioDTO[]>(`${this.settingsState.apiPath}/portfolios/accounts/${accountId}`)
      .pipe(catchError(() => of([])));
  }

  public getPortfoliosGroupedByAccounts(): Observable<AccountPortfoliosHierarchyDTO[]> {
    return this.http
      .get<AccountPortfoliosHierarchyDTO[]>(
        `${this.settingsState.apiPath}/portfolios/grouped-by-accounts`,
      )
      .pipe(catchError(() => of([])));
  }

  public getPortfoliosGroupedByAccountsWithPerformance(
    period: string,
  ): Observable<AccountPortfoliosHierarchyWithPerformanceDTO[]> {
    const url = `${this.settingsState.apiPath}/portfolios/grouped-by-accounts-with-performance`;

    return this.http
      .get<AccountPortfoliosHierarchyWithPerformanceDTO[]>(url, { params: { period: period } })
      .pipe(catchError(() => of([])));
  }

  public getConsolidatedWithPerformance(): Observable<
    AccountPortfoliosHierarchyWithPerformanceDTO[]
  > {
    const url = `${this.settingsState.apiPath}/portfolios/grouped-consolidated-with-performance`;

    return this.http
      .get<AccountPortfoliosHierarchyWithPerformanceDTO[]>(url)
      .pipe(catchError(() => of([])));
  }

  public getGraphicalNavigationPortfolios(
    period: string,
  ): Observable<AccountPortfoliosHierarchyWithPerformanceDTO[]> {
    const url = `${this.settingsState.apiPath}/portfolios/graphical-navigation`;

    return this.http
      .get<AccountPortfoliosHierarchyWithPerformanceDTO[]>(url, { params: { period: period } })
      .pipe(catchError(() => of([])));
  }

  public getGraphicalNavigationConsolidated(): Observable<
    AccountPortfoliosHierarchyWithPerformanceDTO[]
  > {
    const url = `${this.settingsState.apiPath}/portfolios/graphical-consolidated-navigation`;

    return this.http
      .get<AccountPortfoliosHierarchyWithPerformanceDTO[]>(url)
      .pipe(catchError(() => of([])));
  }

  public getPortfolioValuation(portfolioId: string): Observable<PortfolioValuationDTO[]> {
    const url = `${this.settingsState.apiPath}/portfolios/${portfolioId}/valuation`;
    return this.http.get<PortfolioValuationDTO[]>(url).pipe(catchError(() => of([])));
  }

  public getPortfolioFullPerformance(
    portfolioId: string,
  ): Observable<{ [date: string]: PortfolioPerformanceWrapperDTO }> {
    const url = `${this.settingsState.apiPath}/portfolios/${portfolioId}/performance-full`;
    return this.http
      .get<{ [date: string]: PortfolioPerformanceWrapperDTO }>(url)
      .pipe(catchError(() => of(null)));
  }

  public getAvailableAssets(
    portfolioId: string,
    instrumentId: string,
    cashId: string,
  ): Observable<PositionAvailableAssetsDTO> {
    const url = `${this.settingsState.apiPath}/portfolios/${portfolioId}/positions/available-assets`;
    return this.http
      .get<PositionAvailableAssetsDTO>(url, {
        params: {
          cashId: cashId,
          instrumentId: instrumentId,
        },
      })
      .pipe(catchError(() => of(null)));
  }

  public getAvailableAssetsByBankAccounts(
    portfolioId: string,
    instrumentId?: string,
    cashId?: string,
    baClientType?: BankAccountDTO.BaClientTypeEnum,
  ): Observable<AvailableAssetsByBankAccount> {
    let url = `${this.settingsState.apiPath}/portfolios/available-assets-by-bank-accounts/${portfolioId}`;

    if (cashId) {
      url += `/${cashId}`;
    }

    if (cashId && instrumentId) {
      url += `/${instrumentId}`;
    }

    const params: Record<string, string> = {};

    if (baClientType) {
      params.baClientType = baClientType;
    }

    return this.http
      .get<AvailableAssetsByBankAccount>(url, { params })
      .pipe(catchError(() => of({})));
  }

  public getProductAvailableAssets(
    bankAccountId: string,
    productId: string,
    productCcy: string,
  ): Observable<AvailableProductValueDTO> {
    const url = `${this.settingsState.apiPath}/portfolios/bank-accounts/${bankAccountId}/available-product-value`;

    const params: { [param: string]: string } = { productId, ccy: productCcy };

    return this.http
      .get<AvailableProductValueDTO>(url, { params })
      .pipe(catchError(() => of(null)));
  }

  public getBankAccountAvailableAssets({
    bankAccountId,
    portfolioCcy,
    cashId,
    instrumentId,
    productId,
  }: {
    bankAccountId: string;
    portfolioCcy: string;
    cashId?: string;
    instrumentId?: string;
    productId?: string;
  }): Observable<BankAccountAvailableAssetsDTO> {
    const url = `${this.settingsState.apiPath}/portfolios/bank-accounts/${bankAccountId}/available-assets`;

    const params: {
      portfolioCcy?: string;
      cashId?: string;
      instrumentId?: string;
      productId?: string;
    } = {};

    if (portfolioCcy) {
      params.portfolioCcy = portfolioCcy;
    }

    if (cashId) {
      params.cashId = cashId;
    }

    if (productId) {
      params.productId = productId;
    }

    if (instrumentId) {
      params.instrumentId = instrumentId;
    }

    return this.http
      .get<BankAccountAvailableAssetsDTO>(url, {
        params: params,
      })
      .pipe(catchError(() => of(null)));
  }

  public getBankAccountBlockedQty({
    bankAccountId,
    instrumentId,
    ibanId,
    productId,
  }: {
    bankAccountId: string;
    instrumentId: string;
    ibanId?: string;
    productId?: string;
  }): Observable<BlockedQtyDTO> {
    const url = `${this.settingsState.apiPath}/portfolios/bank-accounts/${bankAccountId}/blocked-qty`;

    const params: { instrumentId: string; ibanId?: string; productId?: string } = {
      instrumentId,
    };

    if (ibanId) {
      params.ibanId = ibanId;
    }

    if (productId) {
      params.productId = productId;
    }

    return this.http
      .get<BlockedQtyDTO>(url, {
        params: params,
      })
      .pipe(catchError(() => of(null)));
  }

  public getOmnibusAvailableAssets(
    bankAccountId: string,
    instrumentId?: string,
    cashId?: string,
  ): Observable<PortfolioOmnibusAvailableAssetsDTO> {
    const params: Record<string, string> = { bankAccountId };

    if (instrumentId) {
      params.instrumentId = instrumentId;
    }

    if (cashId) {
      params.cashId = cashId;
    }

    return this.http
      .get<PortfolioOmnibusAvailableAssetsDTO>(
        `${this.settingsState.apiPath}/portfolios/omnibus-available-assets`,
        {
          params,
        },
      )
      .pipe(catchError(() => of(null)));
  }

  public getStrategyPositionSummary(
    portfolioId: string,
    accountId: string,
    sourceEntityType: SourceEntityType,
    requestParams: {
      range?: {
        start: Date;
        end: Date;
      };
      portfolioOpenDate?: Date;
      ccy?: string;
      productId?: string;
      bankAccountIds?: string[];
    },
  ): Observable<PositionSummaryWithPerformanceWidgetsDTO> {
    let url = `${this.settingsState.apiPath}/portfolios`;

    const params: Record<string, string | string[]> = {};

    switch (true) {
      case !portfolioId && !accountId:
        url += `/strategy-position-summary-mobile-consolidated-custom-period`;

        break;

      case sourceEntityType === 'portfolio':
      case sourceEntityType === 'consolidated-portfolio':
        url += `/${portfolioId}/strategy-position-summary-mobile-custom-period`;

        break;

      case sourceEntityType === 'account':
        url += `/accounts/${accountId}/strategy-position-summary-mobile-custom-period`;
        break;
    }

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (
      requestParams.range.start &&
      !this.dateHelper.isEqual(requestParams.range.start, requestParams.portfolioOpenDate) &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.range.end) {
      params.date = this.dateHelper.format(requestParams.range.end);
    }

    if (requestParams.productId) {
      params.productId = `${requestParams.productId}`;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http.get<PositionSummaryMobileDTO>(url, { params });
  }

  public getPositionSummary(
    portfolioId: string,
    accountId: string,
    sourceEntityType: SourceEntityType,
    requestParams: {
      instrumentId: string;
      range?: {
        start: Date;
        end: Date;
      };
      portfolioOpenDate?: Date;
      ccy?: string;
      consolidatedByProduct?: boolean;
      productId?: string;
      bankAccountIds?: string[];
    },
  ): Observable<PositionSummaryMobileDTO> {
    let url = `${this.settingsState.apiPath}/portfolios`;

    const params: Record<string, string | string[]> = {};

    switch (true) {
      case sourceEntityType === 'consolidated-portfolio' || (!portfolioId && !accountId):
        url += `/position-summary-mobile-consolidated-custom-period`;

        if (requestParams.consolidatedByProduct) {
          params.consolidatedByProduct = `${requestParams.consolidatedByProduct}`;
        }

        break;

      case !!portfolioId:
        url += `/${portfolioId}/position-summary-mobile-custom-period`;

        if (requestParams.consolidatedByProduct) {
          params.includeProducts = `${requestParams.consolidatedByProduct}`;
        }

        break;

      case !!accountId:
        url += `/accounts/${accountId}/position-summary-mobile-custom-period`;

        if (requestParams.consolidatedByProduct) {
          params.consolidatedByProduct = `${requestParams.consolidatedByProduct}`;
        }

        break;
    }

    if (requestParams.instrumentId) {
      params.instrumentId = requestParams.instrumentId;
    }

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (
      requestParams.range.start &&
      !this.dateHelper.isEqual(requestParams.range.start, requestParams.portfolioOpenDate) &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.range.end) {
      params.date = this.dateHelper.format(requestParams.range.end);
    }

    if (requestParams.productId) {
      params.productId = `${requestParams.productId}`;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http.get<PositionSummaryMobileDTO>(url, { params });
  }

  public getPositionPerformance(
    portfolioId: string,
    instrumentId: string,
    requestParams: {
      range?: {
        start: Date;
        end: Date;
      };
      ccy?: string;
      consolidatedByProduct?: boolean;
      productId?: string;
      bankAccountIds?: string[];
    },
  ): Observable<PositionChartPerformanceDTO[]> {
    const url = `${this.settingsState.apiPath}/portfolios/${portfolioId}/position-performance/${instrumentId}`;

    const params: Record<string, string | string[]> = {};

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
    }

    if (requestParams.range.start) {
      params.startDate = this.dateHelper.format(requestParams.range.start);
    }

    if (requestParams.range.end) {
      params.date = this.dateHelper.format(requestParams.range.end);
    }

    if (requestParams.consolidatedByProduct) {
      params.consolidatedByProduct = `${requestParams.consolidatedByProduct}`;
    }

    if (requestParams.productId) {
      params.productId = `${requestParams.productId}`;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds.join(',');
    }

    return this.http
      .get<PositionChartPerformanceDTO[]>(url, { params })
      .pipe(catchError(() => of(null)));
  }

  public getNamesByIds(idNames: string[]): Observable<IdNameDTO[]> {
    let params = new HttpParams();
    params = params.append('managerIds', idNames.join(', '));

    return this.http
      .get<IdNameDTO[]>(`${this.settingsState.apiPath}/managers`, { params: params })
      .pipe(catchError(() => of(null)));
  }

  public getPnlByIdList(
    ids: string[],
    requestParams?: {
      startDate?: Date | string;
      date?: Date | string;
      ccy?: string;
    },
  ): Observable<Record<string, PortfolioAumDTO>> {
    const params: { [key: string]: string | string[] } = {
      ids: ids.join(','),
    };

    if (requestParams?.ccy) {
      params['ccy'] = requestParams.ccy;
    }

    if (requestParams?.startDate) {
      params['startDate'] = this.dateHelper.format(requestParams.startDate);
    }

    if (requestParams?.date) {
      params['date'] = this.dateHelper.format(requestParams.date);
    }

    return this.http
      .get<Record<string, PortfolioAumDTO>>(
        `${this.settingsState.apiPath}/portfolios/pnl-by-id-list`,
        {
          params,
        },
      )
      .pipe(catchError(() => of(null)));
  }

  public getReportTypes(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingsState.apiPath}/portfolios/kdb-excel-report-types`)
      .pipe(catchError(() => of(null)));
  }

  public loadPnlAlertsExcel(requestParams: {
    type: string;
    params: string;
  }): Observable<{ file: HttpResponse<Blob>; error: HttpErrorResponse }> {
    const params: { [key: string]: string | string[] } = {};
    params['type'] = requestParams.type;

    if (requestParams.params) {
      params['params'] = requestParams.params;
    }

    // TODO найти способ правильно обработать ошибку
    return this.http
      .get(`${this.settingsState.apiPath}/portfolios/kdb-excel-report`, {
        params,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((result) => {
          return { file: result, error: null };
        }),
        catchError((err) => of({ file: null, error: err })),
      );
  }

  public loadExcelForPortfolios(
    fileName: string,
    searchRequest: ExcelTableRequestDTO,
    date: Date | string = null,
    ccy: string = null,
  ): void {
    const url = `${this.settingsState.apiPath}/portfolios/generate-excel-table`;

    const params: { [key: string]: string } = {};

    if (ccy) {
      params['ccy'] = ccy;
    }

    if (date) {
      params['date'] = this.dateHelper.format(date);
    }

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName, params);
  }

  public loadExcelForPositions(
    fileName: string,
    searchRequest: ExcelTableRequestDTO,
    endDate: string,
    requestParams?: {
      startDate?: string;
      ccy?: string;
      consolidatedByProduct?: boolean;
      isSeparatedByBankAccount?: boolean;
    },
  ): void {
    const url = `${this.settingsState.apiPath}/portfolios/positions-on-date/${endDate}/generate-excel-table`;

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName, requestParams);
  }

  public loadExcelForPortfolioPositions(
    fileName: string,
    searchRequest: ExcelTableRequestDTO,
    requestParams: {
      range: { start: Date; end: Date };
      portfolioOpenDate?: Date;
      portfolioId: string;
      consolidatedByProduct: boolean;
      bankAccountIds: string[];
      ccy: string;
      isSeparatedByBankAccount: boolean;
      bankAccountsNames: string[];
    },
  ): void {
    const params: { [param: string]: string | string[] | boolean } = {};

    const endDate = this.dateHelper.format(requestParams.range.end);
    let url = `${this.settingsState.apiPath}/portfolios/${requestParams.portfolioId}/positions-on-date/`;

    url += endDate;
    url += '/generate-excel-table';

    if (
      requestParams.range.start &&
      !this.dateHelper.isEqual(requestParams.range.start, requestParams.portfolioOpenDate) &&
      !this.dateHelper.isEqual(requestParams.range.start, this.dateHelper.epoch())
    ) {
      const startDate = this.dateHelper.format(requestParams.range.start);
      params.startDate = startDate;
      fileName += `${startDate}--`;
    }

    fileName += endDate;

    if (requestParams.ccy) {
      params.ccy = requestParams.ccy;
      fileName += `_${requestParams.ccy}`;
    }

    if (requestParams.consolidatedByProduct) {
      params.consolidatedByProduct = requestParams.consolidatedByProduct;
      fileName += `_consolidatedByProduct`;
    }

    if (requestParams.bankAccountIds?.length) {
      params.bankAccountIds = requestParams.bankAccountIds;
    }

    if (requestParams.isSeparatedByBankAccount) {
      params.isSeparatedByBankAccount = requestParams.isSeparatedByBankAccount;
      fileName += `_separatedByBA`;
    }

    if (requestParams.bankAccountsNames?.length) {
      fileName += `_${requestParams.bankAccountsNames.join(',')}`;
    }

    fileName += '.xlsx';

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName, params);
  }

  public loadExcelForPortfolioValuations(
    fileName: string,
    searchRequest: ExcelTableRequestDTO,
    portfolioId: string,
  ): void {
    const url = `${this.settingsState.apiPath}/portfolios/${portfolioId}/valuation/generate-excel-table`;

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName);
  }

  public getLatestPricesByPortfolioId(portfolioId: string): Observable<RecalculatedPortfolioDTO> {
    return this.http
      .get<RecalculatedPortfolioDTO>(
        `${this.settingsState.apiPath}/portfolios/${portfolioId}/latest-price`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getLatestPricesByInstrumentId(
    instrumentId: string,
  ): Observable<RecalculatedInstrumentDTO> {
    const url = `${this.settingsState.apiPath}/portfolios/instrument/${instrumentId}/latest-price`;

    return this.http.get<RecalculatedInstrumentDTO>(url).pipe(
      map((recalculatedInstrument) => {
        recalculatedInstrument.price = isNotNaN(recalculatedInstrument.price)
          ? recalculatedInstrument.price
          : 0;

        recalculatedInstrument.ask = isNotNaN(recalculatedInstrument.ask)
          ? recalculatedInstrument.ask
          : 0;

        recalculatedInstrument.bid = isNotNaN(recalculatedInstrument.bid)
          ? recalculatedInstrument.bid
          : 0;

        return recalculatedInstrument;
      }),
      catchError(() => of(null)),
    );
  }

  public getLatestPricesByAccountId(accountId?: string): Observable<RecalculatedPortfolioDTO> {
    const params: { [param: string]: string } = {};

    if (accountId) {
      params.accountId = accountId;
    }

    return this.http
      .get<RecalculatedPortfolioDTO>(
        `${this.settingsState.apiPath}/portfolios/accounts/latest-price`,
        { params },
      )
      .pipe(catchError(() => of(null)));
  }

  public getBankAccountsWithPnlByPortfolio(
    portfolioId: string,
    requestParams?: {
      startDate?: Date | string;
      date?: Date | string;
      ccy?: string;
    },
  ): Observable<BankAccountWithPnlDTO[]> {
    const params: { [key: string]: string | string[] } = {};

    if (requestParams) {
      if (requestParams.ccy) {
        params['ccy'] = requestParams.ccy;
      }

      if (requestParams.startDate) {
        params['startDate'] = this.dateHelper.format(requestParams.startDate);
      }

      if (requestParams.date) {
        params['date'] = this.dateHelper.format(requestParams.date);
      }
    }

    return this.http
      .get<BankAccountWithPnlDTO[]>(
        `${this.settingsState.apiPath}/portfolios/${portfolioId}/ba-pnl`,
        { params },
      )
      .pipe(catchError(() => of(null)));
  }

  public getBenchmarksForTwelveMonthsWidget(
    benchmarks: string[],
    startDate?: Date | string,
    endDate?: Date | string,
  ): Observable<DataWithError<Map<string, SimpleDateValueDTO[]>>> {
    const params: { [key: string]: string | string[] } = {};

    if (startDate) {
      params.startDate = this.dateHelper.format(startDate);
    }

    if (endDate) {
      params.endDate = this.dateHelper.format(endDate);
    }

    if (benchmarks) {
      params.benchmarks = benchmarks.join(',');
    }

    return this.http
      .get<Map<string, SimpleDateValueDTO[]>>(
        `${this.settingsState.apiPath}/portfolios/performance/benchmarks/twelve-month-returns`,
        { params },
      )
      .pipe(
        map((data) => ({ data, error: null })),
        catchError((error) => of({ data: null, error })),
      );
  }
}
