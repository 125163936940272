import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';
import { MenuComponent } from './menu/menu.component';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  imports: [CommonModule, MatMenuModule, DirectivesModule],
  declarations: [MenuPanelComponent, MenuComponent],
  exports: [MenuPanelComponent, MenuComponent],
})
export class MenuModule {}
