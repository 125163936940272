import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomHammerGestureConfig extends HammerGestureConfig {
  constructor() {
    super();

    this.events = ['tap', 'press', 'doubletap'];

    this.overrides = {
      // (по умолчанию 250ms) если не установить больше,
      // то сработает событие click (tap),
      // а если на элементе уже есть подписка на click (tap),
      // то вызовится 2 раза обработчик click (tap).
      press: { time: 600 },
    };
  }
}
