import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyOutletComponent } from './lazy-outlet/lazy-outlet.component';
import { OutletDirective } from './outlet/outlet.directive';
import { LazyProgressDirective } from './lazy-progress/lazy-progress.directive';
import { LazyErrorDirective } from './lazy-error/lazy-error.directive';
import { MediaViewComponent } from './media-view/media-view.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { SvgModule } from '@ui/svg/svg.module';
import { DirectivesModule } from '@shared/directives/directives.module';

@NgModule({
  imports: [CommonModule, PipesModule, SvgModule, DirectivesModule],
  declarations: [
    LazyOutletComponent,
    OutletDirective,
    LazyProgressDirective,
    LazyErrorDirective,
    MediaViewComponent,
  ],
  exports: [
    LazyOutletComponent,
    OutletDirective,
    LazyProgressDirective,
    LazyErrorDirective,
    MediaViewComponent,
  ],
})
export class CoreModule {}
