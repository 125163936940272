<div class="app-dialog-notification">
  <div class="app-dialog-notification__header">
    <app-svg class="app-dialog-notification__icon"
             [icon]="'AttentionSquare'"
             [disabledHover]="true">
    </app-svg>

    <span class="heading-4-special">
      {{ data.title }}
    </span>

    <app-svg [icon]="'Close_24'"
             (click)="_onCloseClick()"
             class="app-dialog-notification__close">
    </app-svg>
  </div>

  <div class="app-dialog-notification__body">
    <span class="subtitle-2"
          style="padding-top: 5px;">
      {{ data.description }}
    </span>

    <mat-expansion-panel class="w-100"
                         [hideToggle]="true"
                         [expanded]="true"
                         style="margin-top: 10px;"
                         #panel="matExpansionPanel">
      <mat-expansion-panel-header collapsedHeight="24px"
                                  expandedHeight="24px"
                                  style="padding: 0px 8px;">
        <mat-panel-title>
          <app-svg [icon]="'ArrowMediumRight_24'"
                   class="app-expander-icon"
                   [class.app-expander-icon--down]="panel.expanded">
          </app-svg>

          <span class="subtitle-4">Show Logs</span>

          <div *ngIf="data.dataLoading$|async"
               class="app-widget-spinner">
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngLet="data.data$|async as _data"
           style="display: block; max-height: 400px; overflow: auto;"
           class="app-scrollbar-block"
           appScrollToBottom
           #appScrollToBottom="appScrollToBottom"
           [ngVar]="_data"
           (ngVarChanged)="appScrollToBottom.scroll()">
        <pre *ngFor="let item of _data"
             style="margin: 0px; font-size: 12px;">{{ item }}</pre>
      </div>
    </mat-expansion-panel>
  </div>

  <div class="app-dialog-notification__footer">
    <app-button type="secondary"
                (click)="_onCloseClick()"
                style="margin-right: 10px;">
      {{ 'common.close'|translate }}
    </app-button>
  </div>
</div>