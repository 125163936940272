import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
  ViewChild,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from '../menu-panel/menu-item';
import { MenuPanelComponent } from '../menu-panel/menu-panel.component';
import { MenuRef } from './menu-ref';
import { MenuConfig } from '@ui/menu/menu/menu-config';
import { MenuItemTemplateContext } from '../menu-panel/menu-panel.types';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [MenuRef],
  host: {
    class: 'app-menu',
  },
})
export class MenuComponent implements OnInit {
  @Input() public set items(value: MenuItem[]) {
    this.items$.next(value);
  }

  @Input() public menuItemTemplate: TemplateRef<MenuItemTemplateContext>;

  @Input() public set config(value: MenuConfig) {
    if (value) {
      this._config = value;
    }
  }

  public get config(): MenuConfig {
    return this._config;
  }

  @Output() public readonly selected: EventEmitter<MenuItem> = this.menuRef.selected;

  @ViewChild(MenuPanelComponent, { static: true }) public readonly menuPanel: MenuPanelComponent;
  @ViewChild(MatMenuTrigger) public matMenuTrigger: MatMenuTrigger;

  public opened$ = new BehaviorSubject(false);
  public items$ = new BehaviorSubject<MenuItem[]>([]);

  private _config: MenuConfig = null;
  private readonly defaultConfig: MenuConfig = {
    xPosition: 'after',
    yPosition: 'below',
    panelClass: 'app-menu_main-panel',
  };

  @HostListener('click', ['$event'])
  public _openMenu(event: MouseEvent): void {
    event.stopPropagation();
    this.matMenuTrigger.openMenu();
  }

  constructor(private menuRef: MenuRef) {}

  public ngOnInit(): void {
    this.configMenu();
  }

  public configMenu(): void {
    for (const key in this.defaultConfig) {
      this.menuPanel.menu[key] = this.defaultConfig[key];
    }

    if (this.config) {
      for (const key in this.config) {
        this.menuPanel.menu[key] = this.config[key];
      }
    }
  }

  public onMenuOpened(): void {
    this.opened$.next(true);
  }

  public onMenuClosed(): void {
    this.opened$.next(false);
  }
}
