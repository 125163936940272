import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { PropType } from '@shared/base/types';
import { EMPTY, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface FormControlError {
  key: string;
  value: any;
}

export abstract class FormHelper {
  public static getChanges<TControl extends AbstractControl>(
    formControl: TControl,
  ): Observable<PropType<TControl, 'value'>> {
    if (!formControl) {
      return EMPTY;
    }

    return formControl.valueChanges.pipe(
      startWith(null),
      map(() => formControl.value),
    );
  }

  public static getFormErrors(form: AbstractControl): FormControlError[] {
    const result: FormControlError[] = [];

    this.getFormControlErrors(form, result);

    return result;
  }

  public static getFormErrorsSkipByControlNames(
    form: AbstractControl,
    skipControlNames: string[],
  ): FormControlError[] {
    const result: FormControlError[] = [];

    this.getFormControlErrors(form, result, null, skipControlNames);

    return result;
  }

  private static getFormControlErrors(
    formControl: AbstractControl,
    result: FormControlError[],
    parentControlKey?: string,
    skipControlNames?: string[],
  ): void {
    const _skipError = (controlPath: string): boolean => {
      if (skipControlNames?.length) {
        const key = controlPath.split('.').pop();
        return skipControlNames.includes(key);
      } else {
        return false;
      }
    };

    if (Array.isArray((formControl as FormArray).controls)) {
      if ((formControl as FormArray).errors) {
        const key = parentControlKey || 'form';
        if (!_skipError(key)) {
          result.push({
            key,
            value: formControl.errors,
          });
        }
      }

      (formControl as FormArray).controls.forEach((control, index) => {
        const calculateKey = parentControlKey ? `${parentControlKey}[${index}]` : `[${index}]`;

        FormHelper.getFormControlErrors(control, result, calculateKey, skipControlNames);
      });
    } else if ((formControl as FormGroup).controls) {
      if ((formControl as FormGroup).errors) {
        const key = parentControlKey || 'form';

        if (!_skipError(key)) {
          result.push({
            key,
            value: formControl.errors,
          });
        }
      }

      Object.entries((formControl as FormGroup).controls).forEach(([key, control]) => {
        if (!_skipError(key)) {
          const calculateKey = parentControlKey ? parentControlKey + '.' + key : key;
          FormHelper.getFormControlErrors(control, result, calculateKey, skipControlNames);
        }
      });
    } else {
      if (formControl.errors) {
        const key = parentControlKey || 'form';

        if (!_skipError(key)) {
          result.push({
            key,
            value: formControl.errors,
          });
        }
      }
    }
  }

  public static logFormErrors(errors: FormControlError[]): void {
    errors.forEach((error) => {
      // eslint-disable-next-line no-console
      console.log(`${error.key}: %c${JSON.stringify(error.value, null, ' ')}`, 'color: red');
    });
  }

  public static clearFormGroupAndChildsValidators(form: FormGroup): void {
    form.clearValidators();

    if (form.controls) {
      Object.values(form.controls).forEach((control) => control.clearValidators());
    }
  }

  public static setFormGroupAndChildsValidators(
    form: FormGroup,
    validators: ValidatorFn | ValidatorFn[],
  ): void {
    form.setValidators(validators);

    if (form.controls) {
      Object.values(form.controls).forEach((control) => control.setValidators(validators));
    }
  }

  public static lessOrEqualThanValidator(value: number): ValidatorFn {
    return (control): ValidationErrors | null => {
      return control.value <= value
        ? null
        : {
            lessOrEqualThan: {
              required: value,
              actual: control.value,
            },
          };
    };
  }

  public static greaterThanValidator(value: number): ValidatorFn {
    return (control): ValidationErrors | null => {
      return control.value > value
        ? null
        : {
            greaterThan: {
              required: value,
              actual: control.value,
            },
          };
    };
  }

  public static arrayLengthValidator(value: number): ValidatorFn {
    return (control: FormArray): ValidationErrors | null => {
      return control.length === value
        ? null
        : {
            arrayLength: {
              required: value,
              actual: control.length,
            },
          };
    };
  }

  public static arrayLengthGreaterThanValidator(value: number): ValidatorFn {
    return (control: FormArray): ValidationErrors | null => {
      return control.length > value
        ? null
        : {
            arrayLengthGreaterThan: {
              required: value,
              actual: control.length,
            },
          };
    };
  }

  public static markErrors(control: AbstractControl): void {
    switch (true) {
      case control instanceof FormGroup:
        control.updateValueAndValidity();

        Object.values((control as FormGroup).controls).forEach((childControl) => {
          childControl.markAsTouched();

          if (childControl.invalid) {
            childControl.updateValueAndValidity();
          }

          if ((childControl as FormGroup | FormArray).controls) {
            FormHelper.markErrors(childControl);
          }
        });
        break;

      case control instanceof FormArray:
        Object.values((control as FormArray).controls).forEach((childControl) => {
          childControl.markAsTouched();

          if (childControl.invalid) {
            childControl.updateValueAndValidity();
          }

          if ((childControl as FormGroup | FormArray).controls) {
            FormHelper.markErrors(childControl);
          }
        });
        break;

      default:
        control.markAsTouched();

        if (control.invalid) {
          control.updateValueAndValidity();
        }

        break;
    }
  }
}
