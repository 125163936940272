import { Directive, ElementRef, forwardRef } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { BaseControl } from '@shared/base/base-control';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { AppDatePipe } from '@shared/pipes/date.pipe';
import { InputState } from './input.state';
import { InputCustomTokens } from './input.types';

@Directive({
  selector: '[appInputDateCustomTransform]',
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: InputDateCustomTransformDirective,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateCustomTransformDirective),
      multi: true,
    },
  ],
})
export class InputDateCustomTransformDirective extends BaseControl<UntypedFormControl> {
  private selected: [InputCustomTokens, number, number?];
  private _value: string;

  private DATE_PIPE = new AppDatePipe();

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private dateHelper: DateHelper,
    public inputState: InputState,
  ) {
    super();
  }

  public get value(): string {
    return this._value;
  }

  public writeValue(value: [InputCustomTokens, number, number?]): void {
    this.selected = value;
    this._value = this.transformSelectedToValue(this.selected);

    this.el.nativeElement.value = this._value;
  }

  private transformSelectedToValue(selected: [InputCustomTokens, number, number?]): string {
    switch (this.inputState.customDate) {
      case 'date-to-step': {
        if (
          selected?.length === 2 &&
          selected[0] === InputCustomTokens.ADD_DAY_TO_NOW &&
          Number.isInteger(selected[1])
        ) {
          const stepDaysSign = Math.sign(selected[1]);

          return `${this.DATE_PIPE.transform(this.dateHelper.now())}  (${
            stepDaysSign ? (stepDaysSign > 0 ? '+' : '-') : ''
          }${Math.abs(selected[1])} days)`;
        } else {
          return null;
        }
      }

      case 'date-from-to-step': {
        if (
          selected?.length === 3 &&
          selected[0] === InputCustomTokens.ADD_DAY_TO_NOW &&
          Number.isInteger(selected[1]) &&
          Number.isInteger(selected[2])
        ) {
          const fromDaysSign = Math.sign(selected[1]);
          const toDaysSign = Math.sign(selected[2]);

          return `${this.DATE_PIPE.transform(this.dateHelper.now())}  (From ${
            fromDaysSign ? (fromDaysSign > 0 ? '+' : '-') : ''
          }${Math.abs(selected[1])} days)  (To ${
            toDaysSign ? (toDaysSign > 0 ? '+' : '-') : ''
          }${Math.abs(selected[2])} days)`;
        } else {
          return null;
        }
      }

      default:
        return null;
    }
  }
}
