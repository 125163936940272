<div class="app-toast-container">
  <div class="app-toast-panel">
    <div *ngIf="isProgressType"
         class="app-toast-panel-spinner">
      <app-spinner size="24px"></app-spinner>
    </div>

    <app-svg *ngIf="isSuccessType"
             [icon]="'SuccessToast'"
             subType="success">
    </app-svg>

    <app-svg *ngIf="isErrorType"
             [icon]="'AttentionCircle'"
             subType="danger">
    </app-svg>

    <span class="app-toast-panel-title control-heading">
      {{ title }}
    </span>

    <app-button *ngIf="message"
                type="secondary"
                (click)="_onBodyVisibleChange()">
      {{ isBodyVisible ? 'Hide' : 'Show more'}}
    </app-button>

    <app-svg [icon]="'Close_24'"
             class="ml-auto"
             (click)="_onClose()">
    </app-svg>
  </div>

  <div *ngIf="isBodyVisible"
       @fadeIn
       class="app-toast-body app-scrollbar-block">
    <pre class="table-body app-text-not-capitalize app-text-white-space-pre">{{ message }}</pre>
  </div>
</div>