import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, concat, debounceTime, Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';
import { InstrumentSearchDTO } from '@shared/dto/positions/instrument-search-dto';
import { InstrumentsService } from '@api/instruments.service';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { LoadingState } from '@shared/types/loading-state';
import { TransactionDTO } from '@shared/dto/positions/transaction-dto';
import { GetNumberWithRound } from '@shared/pipes/number.pipe';
import { TransactionFieldsService } from '@shared/helpers/transaction-fields.service';

export interface NetAmountPortfolioCcyResult {
  loadingState: LoadingState;
  value?: number;
}

@Pipe({
  name: 'getNetAmountPortfolioCcy',
})
export class GetNetAmountPortfolioCcyPipe implements PipeTransform {
  constructor(
    private dateHelper: DateHelper,
    private instrumentsService: InstrumentsService,
    private transactionFieldsService: TransactionFieldsService,
  ) {}

  public transform({
    type,
    qty,
    commission,
    tradeTime = of(undefined),
    priceCcy = of(undefined),
    paymentCcy = of(undefined),
    accrued = of(0),
    instrument = of(undefined),
    price = of(undefined),
    subType = of(undefined),
  }: {
    type: TransactionDTO.TypeEnum;
    qty: Observable<number>;
    commission: Observable<number>;
    tradeTime?: Observable<Date>;
    priceCcy?: Observable<string>;
    paymentCcy?: Observable<string>;
    accrued?: Observable<number>;
    instrument?: Observable<InstrumentSearchDTO>;
    price?: Observable<number>;
    subType?: Observable<TransactionDTO.SubTypeEnum>;
  }): Observable<NetAmountPortfolioCcyResult> {
    const isFxRequired =
      type === TransactionDTO.TypeEnum.BondRedemption ||
      type === TransactionDTO.TypeEnum.BondRedemptionPartial ||
      type === TransactionDTO.TypeEnum.Sell ||
      type === TransactionDTO.TypeEnum.Buy;

    return combineLatest([
      qty,
      commission.pipe(startWith(0)),
      tradeTime,
      priceCcy,
      paymentCcy,
      accrued.pipe(startWith(0)),
      instrument,
      price,
      subType,
    ]).pipe(
      debounceTime(300),
      filter(([, , tradeTime, priceCcy, paymentCcy]) =>
        isFxRequired ? !!(tradeTime && priceCcy && paymentCcy) : true,
      ),
      switchMap(
        ([
          qty,
          commission,
          tradeTime,
          priceCcy,
          paymentCcy,
          accrued,
          instrument,
          price,
          subType,
        ]): Observable<NetAmountPortfolioCcyResult> => {
          const netAmount = this.transactionFieldsService.getNetAmount({
            type,
            qty,
            commission,
            transactionSubType: subType,
            price,
            instrument,
            accrued,
          });

          const getRollingFieldValue$ = isFxRequired
            ? priceCcy === paymentCcy
              ? of(1)
              : this.instrumentsService.getRollingFieldValue(
                  `${priceCcy}${paymentCcy}`,
                  this.dateHelper.format(tradeTime),
                  'Price',
                )
            : of(undefined);

          return concat(
            of({ loadingState: LoadingState.InProgress }),
            getRollingFieldValue$.pipe(
              map((rate: number) => ({
                loadingState: LoadingState.Done,
                value: GetNumberWithRound(rate ? rate * netAmount : netAmount, 2),
              })),
              catchError(() =>
                of({
                  loadingState: LoadingState.Error,
                  value: 0,
                }),
              ),
            ),
          );
        },
      ),
    );
  }
}
