import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip, of } from 'rxjs';
import {
  SystemDateFormats,
  DateFormatSeparator,
  PageFormats,
  PreferencesDTO,
  SymbolPositions,
  DECIMALS_COUNT,
} from '@shared/types/preferences';
import { PreferencesService } from '@api/preferences.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../helpers/auth.service';
import { RoleState } from '@shared/states/role.state';
import { WidgetsService } from '@shared/api/widgets.service';
import { DateHelper } from '@shared/helpers/date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsState {
  private readonly DefaultPreferences: PreferencesDTO = {
    currency: 'USD',
    symbolPosition: SymbolPositions.Left,
    decimalsCount: DECIMALS_COUNT,
    nativeCurrencyHover: false,
    dateFormat: SystemDateFormats.yyyyMMdd,
    pageFormat: PageFormats.A4,
    dailyProfileUpdates: false,
    language: 'en',
    cookieAccepted: false,
  };

  private _preferences$ = new BehaviorSubject<PreferencesDTO>(null);
  private needRunLoad = true;

  public openDate$ = new BehaviorSubject<Date>(null);
  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private preferencesService: PreferencesService,
    private widgetsService: WidgetsService,
    private authService: AuthService,
    private roleState: RoleState,
    private dateHelper: DateHelper,
  ) {}

  public loadIfRequired(): Observable<void> {
    if (!this.needRunLoad) {
      return of(null);
    }

    return this.authService.loggedIn$.pipe(
      filter((loggedIn) => loggedIn),
      switchMap(() => {
        this.loading$.next(true);

        return zip(
          !this._preferences$.value
            ? this.preferencesService.get().pipe(
                tap((preferences) => {
                  this._preferences$.next(
                    preferences
                      ? Object.assign(this.DefaultPreferences, preferences)
                      : this.DefaultPreferences,
                  );
                }),
              )
            : of(null),

          this.widgetsService.getOpenDate().pipe(
            tap((openDate) => {
              this.openDate$.next(this.dateHelper.parse(openDate));
            }),
          ),

          this.roleState.loadCurrentUser(),
          this.roleState.loadDisabledAuthoritiesIfRequired(),
          this.roleState.loadAllUsagesIfRequired(),
        ).pipe(
          map(() => null),
          tap(() => {
            this.loading$.next(false);
            this.needRunLoad = false;
          }),
        );
      }),
    );
  }

  public setPreferences(value: PreferencesDTO): void {
    this._preferences$.next(value);
  }

  public get preferences(): PreferencesDTO {
    return this._preferences$.value || this.DefaultPreferences;
  }

  public get preferences$(): Observable<PreferencesDTO> {
    return this._preferences$.asObservable();
  }

  public getDateFormat(): string {
    const dateFormat = this.preferences.dateFormat;

    return dateFormat ? dateFormat.replace(/-/g, DateFormatSeparator) : SystemDateFormats.yyyyMMdd;
  }
}
