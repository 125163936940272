import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SettingsState } from '@shared/states/settings.state';
import { LazyData } from '@ui/table/table-types';
import {
  ChangePasswordDTO,
  IdNameDTO,
  ManagerConciseDTO,
  ManagerDTO,
  SearchRequestDTO,
  UsageDTO,
} from '@shared/dto/accounts/models';
import { ExcelTableRequestDTO, OrderTransactionTypeInfo } from '@shared/dto/gateway-secured/models';
import { FileLoaderService } from '@shared/helpers/file-loader.service';

export interface UiIdNameDTO extends IdNameDTO {
  managerId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ManagersService {
  constructor(
    private http: HttpClient,
    private settingsState: SettingsState,
    private fileLoaderService: FileLoaderService,
  ) {}

  public updatePassword(data: ChangePasswordDTO): Observable<any> {
    return this.http.put<ChangePasswordDTO>(
      `${this.settingsState.apiPath}/managers/password`,
      data,
    );
  }

  public getManagersWithPagination(
    request: SearchRequestDTO,
    withDeleted: boolean = false,
  ): Observable<LazyData<ManagerDTO>> {
    let url = `${this.settingsState.apiPath}/managers`;

    url += '/search';

    return this.http
      .post<ManagerDTO[]>(url, request, {
        observe: 'response',
        params: {
          withDeleted: `${withDeleted}`,
        },
      })
      .pipe(
        map((res) => {
          return {
            rows: res.body,
            rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
          } as LazyData<ManagerDTO>;
        }),
        catchError(() =>
          of({ rows: [], rowCount: 0, errorResponse: true } as LazyData<ManagerDTO>),
        ),
      );
  }

  public getOne(id: string): Observable<ManagerDTO> {
    return this.http
      .get<ManagerDTO>(`${this.settingsState.apiPath}/managers/${id}`)
      .pipe(catchError(() => of(null)));
  }

  public getNamesByMangersIds(managersIds: string[]): Observable<UiIdNameDTO[]> {
    let params = new HttpParams();
    params = params.append('managerIds', managersIds.filter((id) => !!id).join(', '));

    return this.http
      .get<UiIdNameDTO[]>(`${this.settingsState.apiPath}/managers`, { params: params })
      .pipe(catchError(() => of(null)));
  }

  public getAll(companyId: string = null, withDeleted: boolean = false): Observable<ManagerDTO[]> {
    const params: { [param: string]: string | boolean } = {};

    if (companyId) {
      params.companyId = companyId;
    }

    params.withDeleted = withDeleted;

    return this.http
      .get<ManagerDTO[]>(`${this.settingsState.apiPath}/managers`, {
        params,
      })
      .pipe(catchError(() => of([])));
  }

  public getManagersByIds(ids: string[]): Observable<any[]> {
    return this.http
      .get<ManagerDTO[]>(`${this.settingsState.apiPath}/managers`, {
        params: {
          withDeleted: 'false',
          managerIds: ids,
        },
      })
      .pipe(catchError(() => of([])));
  }

  public getManagersByGroup(
    companyId: string,
    groupType: OrderTransactionTypeInfo.GroupTypeEnum,
    portfolioManagerIds: string[],
  ): Observable<ManagerConciseDTO[]> {
    return this.http
      .get<ManagerConciseDTO[]>(`${this.settingsState.apiPath}/managers/by-group`, {
        params: {
          companyId: companyId,
          groupType: groupType,
          portfolioManagerIds: portfolioManagerIds.join(','),
        },
      })
      .pipe(catchError(() => of([])));
  }

  public getManagersByBankAccount(bankAccountId: string): Observable<ManagerDTO[]> {
    return this.http
      .get<ManagerDTO[]>(`${this.settingsState.apiPath}/managers/bank-accounts/${bankAccountId}`)
      .pipe(catchError(() => of([])));
  }

  public update(manager: ManagerDTO): Observable<ManagerDTO> {
    return this.http
      .put<ManagerDTO>(`${this.settingsState.apiPath}/managers`, manager)
      .pipe(catchError(() => of(null)));
  }

  public updateStatus(managerId: string, active: boolean): Observable<boolean> {
    return this.http
      .put<{ status: string }>(
        `${this.settingsState.apiPath}/managers/activate/${managerId}/${active}`,
        {},
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public create(manager: Partial<ManagerDTO>): Observable<ManagerDTO> {
    return this.http
      .post<ManagerDTO>(`${this.settingsState.apiPath}/managers`, manager)
      .pipe(catchError(() => of(null)));
  }

  public getUsage(): Observable<UsageDTO> {
    return this.http
      .get<UsageDTO>(`${this.settingsState.apiPath}/managers/usage`)
      .pipe(catchError(() => of(null)));
  }

  public loadExcel(
    fileName: string,
    searchRequest: ExcelTableRequestDTO,
    withDeleted: boolean = false,
    companyId?: string,
  ): void {
    const url = `${this.settingsState.apiPath}/managers/generate-excel-table`;

    const params = {
      withDeleted: `${withDeleted}`,
    };

    if (companyId) {
      params['companyId'] = `${companyId}`;
    }

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName, params);
  }
}
