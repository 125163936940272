import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export declare type BlobCashMap = {
  blobSrc: SafeUrl;
  remoteSrc?: string;
};

@Injectable({
  providedIn: 'root',
})
export class BlobLoaderHelperService {
  public blobCashList: { [remoteSrc: string]: BlobCashMap } = {};

  constructor(private http: HttpClient) {}

  public loadBlobFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  public getVideoPart(
    url: string,
    startByte: number,
    endByte: number,
  ): Observable<{ blob: Blob; isLastPart: boolean }> {
    return new Observable((observer) => {
      const controller = new AbortController();
      const signal = controller.signal;

      const fetchPart = async (): Promise<void> => {
        try {
          const response = await fetch(url, {
            headers: {
              Range: `bytes=${startByte}-${endByte}`,
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            signal,
          });

          const blob = await response.blob();

          startByte = endByte + 1;
          endByte += endByte;

          if (startByte < +response.headers.get('content-length')) {
            observer.next({ blob: blob, isLastPart: false });
            fetchPart();
          } else {
            observer.next({ blob: blob, isLastPart: true });
            observer.complete();
          }
        } catch (error) {
          observer.error(error);
        }
      };

      fetchPart();

      return () => {
        controller.abort();
      };
    });
  }

  public createBlobMap(remoteUrl: string, dataUrl: SafeUrl, _?: Blob): BlobCashMap {
    return {
      blobSrc: dataUrl,
      remoteSrc: remoteUrl,
    };
  }

  public getBlobMapByRemoteSrc(src: string): BlobCashMap {
    return this.blobCashList[src];
  }

  public addBlobMap(map: BlobCashMap): void {
    this.blobCashList[map.remoteSrc] = map;
  }
}
