import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UploaderService } from '@shared/helpers/uploader.service';
import { FileData } from '@ui/uploader/uploader.types';
import { Subject, combineLatest, fromEvent } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-file-uploader, [app-file-uploader]',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent extends BaseObject implements OnInit {
  @ViewChild('fileInput')
  public readonly fileInput: ElementRef;

  @Input() public name: string;
  @Input() public format: 'base64' | 'file' = 'file';
  @Input() public multiple: boolean = true;
  @Input() public accept: string;
  @Input() public set clickable(value: boolean) {
    if (this.clickable === value) {
      return;
    }
    this._clickable = value;

    if (!value) {
      this.stopLisenClick$.next(true);
      return;
    }

    if (value) {
      this.startLisenToClick();
    }
  }
  public get clickable(): boolean {
    return this._clickable;
  }
  private _clickable: boolean = true;
  private stopLisenClick$ = new Subject<boolean>();
  @Output() public readonly changeData = new EventEmitter<FileData<File | string>[]>();

  constructor(
    private readonly uploaderService: UploaderService,
    private el: ElementRef<HTMLElement>,
  ) {
    super();
  }
  public ngOnInit(): void {
    if (this.clickable) {
      this.startLisenToClick();
    }
  }
  private startLisenToClick(): void {
    fromEvent(this.el.nativeElement, 'click')
      .pipe(takeUntil(this.stopLisenClick$), takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        const target = event.target as Node;

        if (target.nodeName !== 'INPUT') {
          this.click();
        }
      });
  }
  public onFileChanged(fileEvent: Event): void {
    const allFiles = Object.values((fileEvent.target as HTMLInputElement).files as FileList);

    switch (this.format) {
      case 'base64':
        const allFiles$ = allFiles.map((file) => this.uploaderService.fileToBase64(file));

        combineLatest(allFiles$)
          .pipe(takeUntil(this.destroy$))
          .subscribe((filesToUpload) => {
            this.changeData.emit(filesToUpload);
          });

        break;

      default:
        this.changeData.emit(allFiles.map((file) => ({ name: file.name, file })));
        break;
    }
  }

  public setFiles(files: FileData<File | string>[]): void {
    const input = this.fileInput.nativeElement;
    const dt = new DataTransfer();

    files.forEach((file) => dt.items.add(new File([file.file], file.name)));
    input.files = dt.files;
  }

  public click(): void {
    this.fileInput.nativeElement.click();
  }
}
