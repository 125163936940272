import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DialogSubHeaderComponent } from './dialog-sub-header/dialog-sub-header.component';
import { DialogFooterComponent } from './dialog-footer/dialog-footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from 'app/core/core.module';
import { LazyDialogOutletComponent } from './lazy-dialog-outlet/lazy-dialog-outlet.component';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { DialogOutletComponent } from './dialog-outlet/dialog-outlet.component';
import { DialogInPageOutletComponent } from './dialog-in-page-outlet/dialog-in-page-outlet.component';
import { RouterModule } from '@angular/router';
import { SvgModule } from '@ui/svg/svg.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NotificationsModule } from 'app/notifications/notifications.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { DirectivesModule } from '@shared/directives/directives.module';
import { DialogNotificationComponent } from './dialog-notification/dialog-notification.component';
import { DialogNotificationTitleComponent } from './dialog-notification/dialog-notification-title.component';
import { DialogNotificationDescriptionComponent } from './dialog-notification/dialog-notification-description.component';
import { DialogNotificationBodyComponent } from './dialog-notification/dialog-notification-body.component';
import { DialogNotificationFooterComponent } from './dialog-notification/dialog-notification-footer.component';
import { SvgRegistryService } from '@ui/svg/svg-registry.service';
import { DialogSubFooterComponent } from './dialog-sub-footer/dialog-sub-footer.component';
import { NotificationModule } from '@ui/notification/notification.module';
import { Icons } from '@shared/constants/icons';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SvgModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatMenuModule,
    CoreModule,
    SpinnerModule,
    NotificationModule,
    ButtonsModule,
    NotificationsModule,
    DirectivesModule,
  ],
  declarations: [
    DialogComponent,
    DialogHeaderComponent,
    DialogSubHeaderComponent,
    DialogFooterComponent,
    DialogSubFooterComponent,
    DialogOutletComponent,
    LazyDialogOutletComponent,
    DialogInPageOutletComponent,
    DialogNotificationComponent,
    DialogNotificationTitleComponent,
    DialogNotificationDescriptionComponent,
    DialogNotificationBodyComponent,
    DialogNotificationFooterComponent,
  ],
  exports: [
    DialogComponent,
    DialogHeaderComponent,
    DialogSubHeaderComponent,
    DialogFooterComponent,
    DialogSubFooterComponent,
    DialogInPageOutletComponent,
    DialogNotificationComponent,
    DialogNotificationTitleComponent,
    DialogNotificationDescriptionComponent,
    DialogNotificationBodyComponent,
    DialogNotificationFooterComponent,
  ],
})
export class DialogModule {
  constructor(private svgRegistryService: SvgRegistryService) {
    this.svgRegistryService.preload(Icons.ArrowShortLeft_16);
    this.svgRegistryService.preload(Icons.FullWindow);
    this.svgRegistryService.preload(Icons.MiddleWindow);
    this.svgRegistryService.preload(Icons.SideWindow);
    this.svgRegistryService.preload(Icons.SettingsHorizontalOutline);
  }
}
