import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';
import { ThemeState } from '@shared/states/theme.state';
import { RoleState } from '@shared/states/role.state';
import { NotificationsService } from 'app/notifications/notifications.service';
import { LayoutState } from 'app/layout/layout.state';
import { NavbarItem } from './navbar.types';
import { ALL_NAVBAR_ITEMS } from './navbar.constants';
import { Language } from '@shared/translates/language';
import { TranslateService } from '@shared/translates/translate.service';
import { getElementRect, rectInsideOtherRect } from '@shared/helpers/coordinate.helper';
import { ProfileMenuComponent } from '../profile-menu/profile-menu.component';
import { getDialogPosition } from '@ui/dialog/dialog-position';
import { Rect } from '@shared/types/rect';
import { AppScrollStrategy } from 'app/config/scroll-strategy';
import { SettingsState, SiteName } from '@shared/states/settings.state';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-bg-block-main',
  },
})
export class NavbarComponent extends BaseObject implements OnInit {
  public showMenu: boolean;
  public langList$: Observable<Language[]>;
  public currentLang: string;
  public navbarItems$ = new BehaviorSubject<NavbarItem[]>([]);
  public popupNavbarItems$ = new BehaviorSubject<NavbarItem[]>([]);
  private filteredNavbarItems$ = new BehaviorSubject<NavbarItem[]>([]);
  private calculatedRectsNavItems: Rect[];
  public profileMenuOpened$ = new BehaviorSubject<boolean>(false);

  public readonly isQsf: boolean;

  @ViewChild('nav', { static: true, read: ElementRef }) public navElement: ElementRef<HTMLElement>;

  @ViewChild('navItemTester', { static: true })
  public navItemTesterElement: ElementRef<HTMLElement>;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private cd: ChangeDetectorRef,
    public roleState: RoleState,
    public notificationsService: NotificationsService,
    public layoutState: LayoutState,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    public themeState: ThemeState,
    private settingsState: SettingsState,
  ) {
    super();

    this.isQsf = this.settingsState.siteName === SiteName.Qsf;
  }

  public ngOnInit(): void {
    this.listenToWindowResize();

    this.langList$ = this.translateService.getLangList();
    this.currentLang = this.translateService.getCurrentLang();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.cd.markForCheck());

    this.filteredNavbarItems$.next(
      ALL_NAVBAR_ITEMS.filter((menuItem) => {
        const _hasAuthorities = this.roleState.hasAuthorities(menuItem.authorities, 'one_of');

        return (
          _hasAuthorities ||
          (this.roleState.needSubscription() &&
            this.roleState.hasDisabledAuthorities(menuItem.authorities, 'one_of'))
        );
      }),
    );

    this.calculatedRectsNavItems = this.filteredNavbarItems$.value.map((menuItem) =>
      this.getTextRect(this.translateService.get(menuItem.name)),
    );

    this.updateNavbarOnScreenResize();

    this.notificationsService.getUnreadPushNotificationsCount();
  }

  private listenToWindowResize(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateNavbarOnScreenResize());
  }

  private getTextRect(testText: string): Rect {
    this.navItemTesterElement.nativeElement.textContent = testText;

    return getElementRect(this.navItemTesterElement.nativeElement);
  }

  private updateNavbarOnScreenResize(): void {
    const navRect = getElementRect(this.navElement.nativeElement);
    navRect.right -= 100;
    navRect.width -= 100;

    let widthSum = 0;

    const visibleNavbarItem: NavbarItem[] = [];
    const invisibleNavbarItem: NavbarItem[] = [];

    for (let index = 0; index < this.calculatedRectsNavItems.length; index++) {
      const navItemRect = this.calculatedRectsNavItems[index];

      const navItemTestRect: Rect = {
        left: navRect.left + widthSum,
        right: navRect.left + widthSum + navItemRect.width,
        top: navRect.top,
        bottom: navRect.bottom,
        width: navItemRect.width,
        height: navItemRect.height,
      };

      if (rectInsideOtherRect(navItemTestRect, navRect)) {
        visibleNavbarItem.push(this.filteredNavbarItems$.value[index]);
      } else {
        invisibleNavbarItem.push(this.filteredNavbarItems$.value[index]);
      }

      widthSum += navItemRect.width;
    }

    this.navbarItems$.next(visibleNavbarItem);
    this.popupNavbarItems$.next(invisibleNavbarItem);

    this.cd.detectChanges();
  }

  public _onShowNotificationsClick(): void {
    this.notificationsService.openSystemNotificationsDialog();
  }

  public _onChangeLangClick(code: string): void {
    this.translateService.changeLang(code);
  }

  public _onToMainPageClick(): void {
    this.router.navigate(['/']);
  }

  public _onProfileMenuClick(element: HTMLElement): void {
    const dialog = this.dialog.open<ProfileMenuComponent, void, void>(ProfileMenuComponent, {
      width: '280px',
      height: 'auto',
      position: getDialogPosition(element, 'right'),
      viewContainerRef: this.viewContainerRef,
      autoFocus: false,
      scrollStrategy: new AppScrollStrategy(),
    });

    dialog.afterOpened().subscribe(() => this.profileMenuOpened$.next(true));
    dialog.afterClosed().subscribe(() => this.profileMenuOpened$.next(false));
  }
}
